@import "../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../components/headers";

.product-detail__attributes {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    padding: 0 15px;
    margin-top: 50px;

    @include media-breakpoint-down(md) {
        padding: 0;
    }

    .nav {
        flex-wrap: wrap;
        justify-content: center;
        border: none;
        width: 100%;

        .nav-item {
            position: relative;
            overflow: hidden;
            margin-right: 40px;

            @include media-breakpoint-down(md) {
                margin: 0 10px;
            }

            .nav-link {
                color: $gray-ada;
                text-transform: uppercase;
                padding: 1px 2px 3px;
                outline-offset: 3px;
                border: 0 solid $yellow;
                height: 37px;
                transition: all 0.5s;

                @include media-breakpoint-down(md) {
                    font-size: 22px;
                }

                // tools|features|specs tabs on PDP
                &::after {
                    content: '';
                    position: absolute;
                    top: auto;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 4px;
                    background: $yellow;
                    transform: translate(0, 4px);
                    transition: all 0.5s cubic-bezier(0.4, 0.01, 0.1, 1);
                }

                &.active {
                    color: $black;
                    border-bottom: 4px solid $yellow;

                    &::after {
                        transform: scale(1.4);
                    }
                }

                &:hover,
                &:focus {
                    outline: none;

                    &:not(.nav-link.active) {
                        color: $slate;
                    }

                    &::after {
                        transform: scaleY(0.5) translateY(-1px);
                    }
                }

                /* stylelint-disable selector-pseudo-class-no-unknown */
                &:focus-visible {
                    border-top: 2px solid $slate;
                    border-left: 2px solid $slate;
                    border-right: 2px solid $slate;
                    border-radius: 3px 3px 0 0;
                }

                .tools-features-specs {
                    @include h3();
                }
            }
        }
    }

    .tab-content {
        max-width: 1280px;
        width: 100%;
        background-color: $slate-v2;

        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .product-detail__attributes-tools,
    .product-detail__attributes-features,
    .product-detail__attributes-specs {
        display: flex;
        flex-direction: row;
        margin-right: 0;

        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;
        }

        .product-detail__attributes-tools-list,
        .product-detail__attributes-features-list,
        .product-detail__attributes-specs-list {
            width: 360px;
            background-color: $white;
            margin: 20px;
            margin-right: 0;
            padding: 20px 20px 20px 10px;

            @include media-breakpoint-down(md) {
                width: initial;
                margin-top: 0;
                margin-right: 20px;
                margin-bottom: 30px;
                padding-bottom: 0;
                padding-right: 15px;
            }

            .product-detail__attributes-tools-list-title,
            .product-detail__attributes-features-list-title,
            .product-detail__attributes-specs-list-title {
                font-family: $brand-font-condensed;
                font-size: 25px;
                text-transform: uppercase;
                width: 100%;
                margin-bottom: 15px;
                margin-left: 15px;

                span {
                    padding: 2px;
                }
            }

            ul {
                height: 400px;
                min-width: 310px;
                max-width: 320px;
                overflow-y: scroll;
                list-style: none;
                padding: 0;
                padding-left: 10px;
                margin: 0;
                direction: rtl;

                @include media-breakpoint-down(md) {
                    height: 200px;
                    min-width: initial;
                }

                li {
                    direction: ltr;
                    line-height: 19px;
                    margin: 3px 0;
                    padding: 2px;

                    span {
                        font-family: $brand-font-condensed;
                        font-size: 15px;
                        margin-right: 5px;
                    }
                }

                &::-webkit-scrollbar-thumb {
                    background: $blue;
                    border-radius: 6px;
                }

                &::-webkit-scrollbar {
                    width: 7px;
                }
            }
        }

        .product-detail__attributes-tools-image,
        .product-detail__attributes-features-image,
        .product-detail__attributes-specs-image {
            height: 400px;
            max-width: 700px;
            width: 100%;
            margin: 50px 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            @include media-breakpoint-down(md) {
                height: 250px;
                max-width: inherit;
                padding: 25px 20px;
                margin: 0;
            }

            img {
                max-width: 700px;
                width: 100%;
                height: 100%;
                object-fit: contain;

                @include media-breakpoint-down(md) {
                    max-width: 100%;
                }
            }

            .specs-image-text {
                margin-top: 10px;
                font-family: $univers-bold;
                text-transform: uppercase;
            }
        }

        .product-detail__attributes-specs {
            .product-detail__attributes-specs-image {
                padding-top: 50px;
                padding-bottom: 50px;
            }
        }
    }

    .product-detail__attributes-features-list,
    .product-detail__attributes-tools-list {
        .product-detail__attributes-features-list-content-title {
            font-size: 14px;
            font-family: $univers;
            font-weight: bold;
        }

        .product-detail__attributes-features-list-content-description {
            margin-top: 3px;
            line-height: initial;
        }

        li {
            display: flex;
            flex-direction: row;

            &:first-child {
                .product-detail__attributes-features-list-content-description {
                    margin-top: 5px;
                    margin-bottom: 8px;
                }
            }
        }
    }

    .product-detail__attributes-specs-list-content {
        font-family: $univers;
        font-size: 14px;

        .product-detail__attributes-specs-group {
            margin-bottom: 20px;
            direction: ltr;

            li span {
                font-family: $univers-bold;
                text-transform: uppercase;
                margin-right: 2px !important;
            }

            .sublist {
                margin-top: 20px;
            }
        }
    }
}
