.badge {
    font-family: $brand-font;
    font-size: 13px;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    display: inline-block;
}

.badge-small {
    font-size: $sm-font-size;
    height: 20px;

    &::after {
        display: inline-block;
        border: 10px solid $slate;
        border-left-width: 0;
        border-right-color: transparent;
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(96%);
    }

    &.badge-chained {
        margin-left: 10px;

        &::before {
            display: inline-block;
            border: 10px solid $slate;
            border-right-width: 10px;
            border-top-color: transparent;
            border-bottom-color: transparent;
            border-left-color: transparent;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            transform: translateX(-100%);
        }
    }

    &.badge-primary {
        .badge-label {
            color: $white;
            background-color: $slate;
        }

        &.badge-yellow {
            .badge-label {
                color: $slate;
                background-color: $yellow;
            }

            &.badge-small::after {
                border: 10px solid $yellow;
                border-left-width: 0;
                border-right-color: transparent;
            }

            &.badge-chained::before {
                border: 10px solid $yellow;
                border-right-width: 10px;
                border-top-color: transparent;
                border-bottom-color: transparent;
                border-left-color: transparent;
            }
        }
    }

    &.badge-secondary {
        .badge-label {
            color: $white;
            background-color: $blue;
        }

        &::after {
            border-top-color: $blue;
            border-bottom-color: $blue;
        }

        &.badge-chained {
            &::before {
                border-right-color: $blue;
            }
        }
    }

    .badge-label {
        height: 100%;
        display: inline-block;
        line-height: 20px;
        padding: 0 8px;
    }

    &.badge-custom {
        &::after {
            display: none;
        }

        &::before {
            display: inline-block;
            content: '';
            background: url("../images/ui/icon_custom.svg") no-repeat;
            background-size: contain;
            width: 20px;
            height: 20px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        .badge-label {
            padding-left: 22px;
        }
    }

    &.badge-custom-chained {
        &::before {
            left: 10px;
        }

        .badge-label {
            padding-left: 32px;
        }
    }
}

.badge-tool-count {
    color: $slate;
    background-color: $white;
    border: 2px solid $slate;
    border-radius: 50%;
    width: 120px;
    height: 120px;

    .badge-count {
        font-size: 55px;
        line-height: 22px;
        padding: 29px 0 21px;
        position: relative;

        &::after {
            display: inline-block;
            content: '';
            width: 55px;
            height: 2px;
            background-color: $slate;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .badge-label {
        font-size: 20px;
        line-height: 1em;
        padding-top: 7px;
    }

    &.badge-alt {
        width: 60px;
        height: 60px;

        .badge-count {
            font-size: 25px;
            padding: 8px 0 3px;

            &::after {
                width: 25px;
            }
        }

        .badge-label {
            font-size: 12px;
            padding-top: 2px;
        }
    }
}
