@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "components/headers";

.item-attributes {
    vertical-align: top;
    padding-left: 0;

    .item-attribute {
        margin: 0;
        font-size: 14px;
    }

    .item-attribute-name {
        font-size: 14px;
        font-weight: bold;
    }

    &__spectrum-header {
        @include h6();

        margin: 20px 0;
        text-transform: uppercase;
    }

    &__spectrum-group {
        @include h6();

        margin-top: 20px;
        text-transform: uppercase;
    }
}

dl.line-item-attributes {
    display: flex;

    dt {
        margin-right: 3px;
    }

    dd {
        margin-bottom: 0;
    }
}

.line-item-attributes,
.line-item-option {
    margin: 0;
}

.line-item-name {
    white-space: normal;
    text-transform: uppercase;
    @include h4();

    /* stylelint-disable selector-pseudo-class-no-unknown */
    a:focus-visible {
        outline: 2px solid $slate;
        border-radius: $border-radius;
        outline-offset: 2px;
    }
}

.cart-card-edit-remove,
.cart-card-save-add {
    button:focus-visible {
        outline: 2px solid $slate;
        border-radius: $border-radius;
        outline-offset: 3px;
    }
}

dl.line-item-pricing-info {
    display: flex;

    dt {
        margin-right: 3px;
    }

    dd {
        margin-bottom: 0;
    }
}

.line-item-pricing-info {
    margin-bottom: 0;

    &.quanity-label {
        margin-bottom: 12px;
    }

    + .price {
        font-size: 1rem;
        font-weight: normal;

        .sales {
            font-weight: normal;
        }
    }
}

.line-item-unit-price {
    width: 100px;
    flex: initial;

    &__spectrum-price-title,
    &__engraving-price-title {
        font-weight: bold;
    }

    &__spectrum-price-sales,
    &__engraving-price-sales {
        color: $red;
    }

    &__spectrum-price-list,
    &__engraving-price-list {
        text-decoration: line-through;
    }
}

.line-item-price-quantity-info {
    margin-top: 0.625em;
    border-top: 1px solid $horizontal-rule-grey;
}

.line-item-total-text {
    font-family: $brand-font;
    text-transform: uppercase;
    font-size: 15px;
}

.pricing {
    font-size: 1em;
    font-weight: bolder;
}

.item-image {
    height: 5.625em;
    width: 5.625em;
    margin-right: 0.938em;
    flex-grow: 0;
    flex-shrink: 0;
}

.line-item-promo {
    color: $red;
    font-size: 0.813em;
}

.line-item-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.bundled-line-item + .bundled-line-item {
    margin-top: 0.625em;
}

.bundle-includes {
    font-size: 0.813em;
    margin-bottom: 0.625em;
}

.line-item-divider {
    margin: 0.625em -1.225em 0.625em -1.325em;
}

.line-dotted {
    border-top: 0.063em dashed #ccc;
}
