
.select2-dropdown {
    border-color: #737373 !important;
    box-shadow: 0 0 0 0.2rem #737373 !important;

    &.select2-dropdown-below {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }
}

.select2-search__field {
    border: 1px solid #393b42 !important;
    border-radius: 0 !important;
}

.select2-container {
    &.select2-container--focus {
        .select2-selection {
            border-color: #737373 !important;
            box-shadow: 0 0 0 0.17rem #737373 !important;
            color: #495057 !important;
            outline: 0 !important;
        }
    }

    .select2-selection {
        border-radius: 0 !important;
        border: 1px solid #393b42 !important;
        color: #393b42 !important;
        height: 45px !important;

        .select2-selection__rendered {
            display: flex !important;
            align-items: center !important;
            height: 100% !important;
        }
    }
}
