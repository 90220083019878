@import "../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/variables";

.dw-apple-pay-button.apple-pay-cart,
.dw-apple-pay-button.apple-pay-cart:hover {
    padding: 0.5em 1em;
    margin: auto;
    border-radius: $border-radius;
}

.dw-apple-pay-button.apple-pay-pdp,
.dw-apple-pay-button.apple-pay-pdp:hover {
    padding: 0.5em 1em;
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    border-radius: $border-radius;
}

@include media-breakpoint-down(xs) {
    #apple-pay-cart-button {
        margin-top: 10px;
    }
}

.dw-apple-pay-button {
    width: 100%;
    min-height: 46px !important;
    border-radius: 0 !important;
    margin: 0 auto !important;
}

/* stylelint-disable selector-type-no-unknown */
isapplepay.btn {
    display: none !important;
}

.cart-and-ipay {
    justify-content: center;

    .pdp-checkout-button {
        padding: 0 1.5em;

        .add-to-cart {
            padding: 0.5em 1em;
        }

        .add-to-cart-global {
            padding: 0.5em 1em;
        }
    }
}

.applepay-tab-wrapper {
    display: none;
}

body.apple-pay-enabled .applepay-tab-wrapper {
    display: block;
}

.prices-add-to-cart-actions:not(.stick-to-top) {
    .add-to-cart,
    .add-to-cart-global {
        flex-basis: 0;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;

        @include media-breakpoint-down(xs) {
            max-width: 100%;
        }
    }
}
