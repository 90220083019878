.modal-open,
.modal {
    overflow: hidden;

    @include media-breakpoint-up(md) {
        padding-right: 16px !important;
    }

    @include media-breakpoint-up(lg) {
        padding-right: 17px !important;
    }
}

.modal-backdrop {
    z-index: 1064;

    &.show[modal-index="1"] {
        z-index: 1069;
    }

    &.show[modal-index="2"] {
        z-index: 1074;
    }
}

.modal {
    z-index: 1065;

    &.show[modal-index="1"] {
        z-index: 1070;
    }

    &.show[modal-index="2"] {
        z-index: 1075;
    }

    @include media-breakpoint-up(sm) {
        .modal-dialog {
            max-width: 530px;
        }
    }

    .modal-content {
        position: relative;
        border: none;
        border-radius: 0;

        .lightbox-image {
            width: 100%;
        }

        .close {
            position: absolute;
            top: 15px;
            right: 15px;
            width: 30px;
            height: 30px;
            padding: 0;
            margin: 0;
            opacity: 1;

            i {
                width: 15px;
                height: 15px;
                background-size: unset;
            }

            /* stylelint-disable selector-pseudo-class-no-unknown */
            &:focus,
            &:focus-visible {
                outline: 2px solid $slate;
                outline-offset: 2px;
            }
        }

        button.slider-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            background: unset;
            border: none;
            padding: 0;
            margin: 0;
            appearance: none;
            z-index: 1;

            &:hover {
                opacity: 0.75;
            }

            &:focus-visible {
                outline: 2px solid $white;
                outline-offset: 2px;
            }

            &.left-arrow {
                left: 10px;

                i {
                    @include icon-arrow-left-color($white);
                }
            }

            &.right-arrow {
                right: 10px;

                i {
                    @include icon-arrow-right-color($white);
                }
            }
        }
    }

    .modal-header {
        border: none;
    }

    .modal-body {
        flex: 0 0 auto;
        padding: 0 60px 60px;

        @include media-breakpoint-down(sm) {
            padding: 0 15px 15px;
        }
    }

    &.media-lightbox {
        text-align: left;
        padding-right: 0;

        .close {
            filter: brightness(5);

            i {
                width: 30px;
                height: 30px;
            }

            &:focus,
            &:focus-visible {
                outline: 2px solid $white;
                outline-offset: 2px;
            }
        }

        .modal-dialog {
            max-width: 1200px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin: 0;
        }

        .modal-content {
            background: transparent;
        }

        .modal-body {
            margin-top: 60px;
            @include media-breakpoint-down(md) {
                @media (orientation: $orientation-landscape) {
                    // Only works with 16x9 content
                    padding: 0 calc((100vw - ((100vh - 75px) * 1.77)) / 2) 15px;
                }
            }
        }
    }
}

// sheath modal custom
#sheath-modal,
#packaging-modal {
    .modal-dialog {
        @include media-breakpoint-down(sm) {
            max-width: 90%;
            margin: 20px auto;
        }

        max-width: 483px;
    }

    .modal-body {
        max-height: 700px;
        margin-top: 50px;
    }

    h3 {
        padding-left: 20px;
        text-transform: uppercase;
    }
}

#sheath-modal {
    img {
        display: block;
        max-width: 216px;
        max-height: 438px;
        width: 100%;
        height: auto;
        margin: 0 auto 40px;
    }
}

#packaging-modal {
    img {
        display: block;
        max-width: 360px;
        max-height: 438px;
        width: 100%;
        height: auto;
        margin: 0 auto 40px;
    }
}

#newsletterPopupModal {
    &.newsletter-modal {
        position: inherit;
    }

    .popup-dialog {
        position: fixed;
        width: 503px;
        max-height: 500px;
        left: 10px;
        bottom: -11px;
        z-index: 2147483647;
        display: inherit;
        margin: 1.75rem auto;

        @include media-breakpoint-down(xs) {
            width: 100%;
            left: 0;
            margin: 0;
            bottom: -56px;
            max-height: 500px;
        }

        .popup-content {
            padding: 40px 50px;
            background-color: $blue-v2;
            border: 1px solid rgba(0, 0, 0, 0.2);
            position: relative;

            @include media-breakpoint-down(xs) {
                bottom: 56px;
                width: 100%;
                left: 0;
                margin: 0;
                padding: 0 20px 20px;
            }

            .popup-body {
                padding: 0;
                position: relative;

                .popup-close {
                    position: absolute;
                    width: 20px;
                    height: 30px;
                    padding: 0;
                    margin: 0;
                    opacity: 1;
                    line-height: 0;
                    font-family: $body-font;
                    font-weight: $base-font-weight;
                    border: none;
                    background-color: $blue-v2;
                    right: -35px;
                    top: -30px;

                    @include media-breakpoint-down(xs) {
                        position: relative;
                        left: 86vw;
                        top: 4px;
                    }

                    i {
                        width: 15px;
                        height: 15px;
                        background-size: unset;
                    }
                }

                .newsletter-popup {
                    display: flex;
                }

                .popup-header {
                    @include h4();

                    text-transform: uppercase;
                }

                .popup-subheader {
                    font-family: $body-font;
                    font-size: $sm-font-size;
                    text-align: left;
                    color: $slate;
                    margin-bottom: 10px;
                }

                .form-group {
                    width: 100%;

                    &.email-address {
                        margin-bottom: 0;
                    }

                    .newsletter-popup-input-label {
                        margin-bottom: 0.25rem;
                    }

                    .name-popup-input,
                    .email-popup-input {
                        width: 100%;
                        font-size: $lg-font-size;
                    }

                    input[name="hpFirstName"] {
                        margin-top: 0;
                        margin-left: -1px;
                    }

                    .email-popup-input {
                        margin-left: -1px;
                    }
                }

                .submit-popup-input {
                    width: calc(100% + 1px);
                    margin-top: 10px;
                }

                span {
                    width: 100%;
                }
            }

            .dpc-copy {
                font-size: $sm-font-size;
            }

            a.links,
            .dpc-copy a {
                cursor: pointer;
                text-decoration: underline;
                color: $slate;
            }

            p {
                margin: 0;
            }

            .privacy-copy {
                margin-top: 5px;
            }

            &.popup-content-submitted {
                height: 82px;
            }

            .confirmation-message {
                padding: 0;
                @include media-breakpoint-down(xs) {
                    padding-top: 20px;
                }
            }
        }
    }
}

.lightbox-dialog {
    margin: 0 auto;
    @include media-breakpoint-up(md) {
        min-width: 800px;
    }

    @include media-breakpoint-up(sm) {
        max-width: 100%;
    }
}

#lb-modal .lightbox-dialog {
    // Keep the aspect ratio and the entire modal in the viewport
    padding: 0 calc((min(100vw, 1440px) - ((100vh - 75px) * 1.65)) / 2) 0;

    @include media-breakpoint-up(md) {
        max-width: 1440px;
    }

    @include media-breakpoint-up(sm) {
        width: 100%;
    }
}

body {
    padding: 0 !important;
}
