.veil {
    position: absolute;
    z-index: 1060;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .underlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $spinner-underlay-color;
    }
}

.spinner {
    width: $spinner-size;
    height: $spinner-size;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: $spinner-size / 2 * -1;
    margin-left: $spinner-size / 2 * -1;
    background-image: url('../images/logo_spinner.svg');
    background-size: contain;
    background-repeat: no-repeat;
}
