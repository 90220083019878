@import "../utilities/swatch";
@import "productCommon";
@import "../product/detail";
@import "../loyalty/badge";
@import "variables";

#quickViewModal.modal .modal-dialog.quick-view-dialog,
.choose-bonus-product-dialog {
    max-width: 995px;

    .selectable-bonus-product-line-item {
        margin-top: 0.2em;
        margin-bottom: 0.2em;
        padding-top: 0.3em;
        padding-bottom: 0.3em;
    }

    .beenSelected {
        background-color: $grey2;
    }

    .availablity-container {
        text-align: right;
    }

    .availablity-container,
    .size-chart {
        margin-top: 0.938em;
    }

    .modal-content {
        max-height: 95vh;
        overflow-y: auto;
        padding-top: 50px;
    }

    .modal-body {
        min-height: 30vh;
        padding: 0 35px 50px;
    }

    .product-details-top {
        .quickview-images {
            width: 541px;
            flex: 0 0 541px;
        }

        .quickview-variations {
            flex-grow: 1;
            max-width: 100%;
        }

        .product-name {
            @include h3();

            text-transform: uppercase;
        }
    }

    .primary-images.col-12 {
        padding: 0;
        width: 511px;
    }

    .set-item__image {
        flex: 0 0 511px;

        img {
            width: 100%;
        }
    }

    .product-detail {
        .product-attribute-option .swatch-circle {
            width: 30px;
            height: 30px;
            border-radius: 50%;
        }

        .tool-count {
            left: 20px;
        }
    }

    .product-set-detail .set-item .prices {
        padding-top: 7px;
    }

    .pdp-slider {
        flex-direction: column-reverse;

        .slider {
            float: none;
            width: 100%;
            margin-bottom: 16px;
        }

        ul.slider-nav-thumbnails {
            width: 90%;
            margin: 0 auto;
            position: relative;
            overflow-x: unset;
            height: unset;

            li.thumbnail {
                clear: none;
                width: 100% !important;
                margin-right: 10px;
                margin-bottom: 0;
                border: 1px solid $slate-v1;
                max-width: 67px;
                max-height: 67px;

                &.slick-current {
                    border-color: $slate;
                    border-width: 2px;

                    &:focus {
                        border-color: $gray-ada;
                    }
                }

                &:focus {
                    border-color: $slate;
                }
            }

            .pdp-video {
                width: 67px;
                height: 67px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-color: transparent;

                &.slick-current {
                    border-color: $slate;
                }

                .icon {
                    width: 52px;
                    height: 52px;
                }
            }

            .slick-arrow {
                background-color: $white;
                width: 26px;
                height: 26px;
                margin: 0;
                border: none;
                color: transparent;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            .slick-prev.slick-arrow {
                left: -36px;

                &:focus {
                    outline: none;
                }

                &:hover {
                    border-color: $slate;
                }

                &::before {
                    content: url("../../images/ui/icon_arrow_left.svg");
                    position: static;
                }
            }

            .slick-next.slick-arrow {
                right: -26px;

                &:focus {
                    outline: none;
                }

                &:hover {
                    border-color: $slate;
                }

                &::before {
                    content: url("../../images/ui/icon_arrow_right.svg");
                    position: static;
                }
            }
        }
    }

    .full-pdp-link-container {
        width: 100%;
        text-align: center;
    }

    .modal-footer {
        border-top: 1px solid $slate;
    }

    .promotions {
        div.collapsible-xl button.callout {
            text-decoration: none;
            border: none;
            background-color: transparent;
        }
    }

    .bonus-summary {
        @include media-breakpoint-down(sm) {
            font-size: 0.625em;
        }

        .bonus-product-name {
            @include media-breakpoint-down(sm) {
                padding: 0;
            }
        }
    }

    .pre-cart-products {
        margin-right: 0.125em;
    }

    #saveDialog {
        .modal-dialog {
            @include media-breakpoint-up(md) {
                width: 716px;
                max-width: 716px;
            }
        }

        .modal-header {
            padding: 0 40px;
            @include media-breakpoint-up(md) {
                padding: 40px 90px 0;
            }

            .modal-title {
                width: 100%;
                padding-bottom: 20px;
                margin-bottom: 15px;
                border-bottom: solid 2px $slate;
            }
        }

        .modal-body {
            padding: 5px 40px 40px;
            @include media-breakpoint-up(md) {
                padding: 5px 90px 90px;
            }

            a {
                text-decoration: underline;
            }

            .wishlist-product-card .product-img {
                max-width: 140px;
            }

            .wishlist-buttons {
                margin-top: 25px;

                &.row {
                    margin-left: -10px;
                    margin-right: -10px;
                }

                .col-6 {
                    padding-left: 10px;
                    padding-right: 10px;
                }

                .wishlist-button {
                    width: 100%;
                }
            }
        }
    }

    #socialDialog {
        .modal-dialog {
            @include media-breakpoint-up(md) {
                width: 728px;
                max-width: 728px;
            }
        }

        .modal-header {
            padding: 40px 40px 0;
            @include media-breakpoint-up(md) {
                padding: 90px 90px 0;
            }
        }

        .modal-body {
            padding: 32px 40px 40px;
            @include media-breakpoint-up(md) {
                padding: 32px 90px 90px;
            }
        }

        .modal-title {
            text-transform: uppercase;
        }
    }
}

#yt-modal.media-lightbox {
    .modal-dialog {
        max-width: 1200px;
    }

    // style was applying on non-mobile
    $xs: 544px;
    @media screen and (max-height: $xs) {
        @media (orientation: $orientation-landscape) {
            button.icon-close {
                right: 150px;
            }

            .modal-body {
                padding: 0 calc((100vw - ((100vh - 80px) * 1.77)) / 2) 15px;
            }
        }
    }
}

#sheath-modal,
#packaging-modal {
    .modal-header {
        background-color: $white;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
    }
}
