@import "../variables";

.hero {
    height: 25vw;
    background-size: cover;
    background-position: 50%;
    position: relative;
}

.slant-down {

    @include media-breakpoint-up(sm) {
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 0;
            height: 0;
            border: 0 solid transparent;
            border-right-width: 0;
            border-left-width: 90vw;
            border-bottom: 4vw solid $body-bg;
        }
    }
}

.slant-up {

    @include media-breakpoint-up(sm) {
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            border: 0 solid transparent;
            border-left-width: 0;
            border-right-width: 90vw;
            border-top: 4vw solid $body-bg;
        }
    }
}
