@import "bootstrap/scss/mixins/breakpoints";
@import "../variables";

ul.tags-container {
    display: flex;
    flex-wrap: wrap;

    li {
        list-style: none;

        &.tag-value {
            position: relative;
            color: $white;
            background-color: $blue-v1;
            border-radius: 5px;
            border: solid 1px $blue;
            margin: 0 2.5px 5px;

            button {
                color: $slate;
                border: none;
                background: none;
                padding: 0 25px 0 10px;

                &::before {
                    content: "+";
                    position: absolute;
                    right: 5px;
                    top: -7px;
                    transform: rotate(45deg);
                    font-size: 22px;
                }
            }
        }
    }
}
