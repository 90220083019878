@import "../variables";

.loyalty-points-badge {
    border-top: 1px solid #e1e2e3;
    margin: 20px 0;
    padding: 20px 0;
    display: flex;
    align-items: center;

    i {
        height: 40px;
        content: url("../../icons/loyalty/pointed_earned.svg");
    }

    a {
        font-family: $body-font-bold;
        text-decoration: underline;
        display: inline-block;

        /* stylelint-disable selector-pseudo-class-no-unknown */
        &:focus-visible {
            outline: 2px solid $slate;
            border-radius: $border-radius;
            outline-offset: 2px;
        }
    }

    .loyalty-points-badge-text {
        margin-left: 10px;

        a:focus-visible {
            outline: 2px solid $slate;
            border-radius: $border-radius;
            outline-offset: 2px;
        }
    }
}

.product-loyalty-badge:empty {
    display: none;
}

.cart-loyalty-badge {
    margin: 0;
    border-top: 1px solid $gray;
    padding: 20px 0 0;
}

.product-quickview .loyalty-points-badge {
    border-top: none;
    margin: 0;
}

.product-set-detail .loyalty-points-badge {
    border-top: none;
    margin: 4px 0;
    padding: 0;
}

.set-items .loyalty-points-badge {
    border-top: none;
    margin: 5px 0;
    padding: 5px 0 0;
}

.loyalty-warranty-badge {
    display: flex;
    align-items: center;

    i {
        height: 30px;
        content: url("../../icons/loyalty/warranty.svg");
    }

    .loyalty-warranty-badge-text {
        font-family: $trade-gothic-bold-condensed;
        font-size: $sm-font-size;
        margin-left: 5px;
    }
}
