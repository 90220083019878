@mixin text-body() {
    font-family: $base-font-family;
    font-size: 14px;
    color: $slate;
}

body,
html {
    @include text-body();
}

@mixin text-lg() {
    font-size: 16px;
}

.text-lg {
    @include text-lg();
}

.text-lg-left {
    @include text-lg();
}

@mixin text-sm() {
    font-size: 12px;
}

.text-sm {
    @include text-sm();
}

.text-sm-left {
    @include text-sm();
}

@mixin text-xs() {
    font-size: 10px;
}

.text-xs {
    @include text-xs();
}

.text-xs-left {
    @include text-xs();
}
