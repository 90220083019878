@import "menu";
@import "headers";

$banner-padding: 0.3125em;
$menu-padding: 0.5em;
$menu-item-margin: 0.625em;

.header-nav .header {
    position: relative;
    background-color: $white;

    @include media-breakpoint-down(md) {
        border-bottom: 1px solid $gray;

        .main-row {
            padding-right: 0;
        }
    }

    .search-cart-user {
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        @include media-breakpoint-down(sm) {
            justify-content: space-between;
        }
    }
}

.header-nav.sticky-top {
    z-index: 1050;
    transition: 0.5s;
}

.header-nav.menu {
    @include media-breakpoint-down(md) {
        z-index: 1051;
        position: relative;
    }
}

.header-nav {
    .greeting {
        max-width: 180px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-family: $body-font-bold;
    }

    .nav-item.dropdown-menu__mobile .greeting {
        font-family: $body-font;
    }
}

.header__country-selector {
    width: 200px;
    padding: 0;
    padding-left: 10px;
    padding-top: 2px;

    .flag-icon {
        margin-right: 10px;
    }

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
}

#countrySelector .modal-dialog {
    max-width: 360px;
}

.country-selector {
    .modal-header {
        padding: 25px 25px 0;
    }

    .modal-body {
        padding: 0 25px 25px !important;
    }

    .country-selector__header {
        width: 100%;
        font-size: $heading-5-font-size;
        font-family: $brand-font;
        text-transform: uppercase;
        line-height: 1;
        text-align: center;
        margin: 25px 0 5px;
    }

    .flag {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .flag-icon {
        line-height: 30px;
        width: 50px;
    }

    .btn-secondary {
        margin: 7px 0;
    }

    .country-selector-button {
        padding-top: 13px;

        &:hover {
            text-decoration: none;
        }

        .fa-globe {
            font-size: $heading-4-font-size;
            margin-right: 8px;
        }
    }
}

.header-logo {
    width: 200px;
    margin-left: 25px;

    a {
        display: block;
        text-indent: -9999px;
        background-image: url('../images/logo_blk.svg'), none;
        background-size: 200px 33px;
        width: 200px;
        height: 33px;

        &:focus {
            outline: none;
        }

        /* stylelint-disable selector-pseudo-class-no-unknown */
        &:focus-visible {
            outline: 3px solid $slate;
            border-radius: $border-radius;
            outline-offset: 5px;
        }
    }

    @include media-breakpoint-down(lg) {
        margin: 20px 0 20px 25px;
    }

    @include media-breakpoint-down(md) {
        margin: 20px 0;
    }
}

// for 'pageHeaderNomenu' aka checkout
// there is no skip-link here, there's nothing to skip
.invisible-accessibility-statement {
    position: absolute;
    left: 0;
    top: -4.2em;
    overflow: hidden;
    padding: 1em 1.5em;
    background: $white;
    transition: all 0.2s ease-in-out;

    &:active,
    &:focus,
    &:hover {
        left: 0;
        top: 0;
        width: auto;
        height: auto;
        z-index: 10000000;
        background: $white;
        transition: all 0.2s ease-in-out;
    }
}

.header-banner-logo {
    position: relative;
    background-color: $white;
    z-index: 1050;
}

.header-secure-checkout {
    text-transform: uppercase;
    padding-right: 15px;
    @include h6();

    i {
        width: 18px;
        height: 18px;
        margin-right: 10px;
    }
}

.navbar-header {
    height: 40px;
    background-color: $white;
    justify-content: flex-end;

    @include media-breakpoint-up(lg) {
        justify-content: space-between;
    }

    @include media-breakpoint-down(md) {
        height: 65px;
    }

    @include media-breakpoint-down(xs) {
        justify-content: space-between;
    }

    .user,
    .country-selector,
    .minicart {
        display: inline-block;
    }

    .user,
    .country-selector,
    .minicart,
    .navbar-toggler {
        height: auto;
    }

    .navbar-toggler {
        padding: 10px 15px;
        margin: 0 5px;

        .hamburger {
            width: 28px;
            height: 30px;
            position: relative;

            span {
                display: block;
                position: absolute;
                height: 3px;
                width: 100%;
                background: $slate;
                border-radius: 9px;
                left: 0;

                &:nth-child(1) {
                    top: 3px;
                }

                &:nth-child(2) {
                    top: 12px;
                }

                &:nth-child(3) {
                    top: 21px;
                }
            }
        }

        &:focus {
            outline: none;
        }

        &:focus-visible {
            outline: 3px solid $slate;
            border-radius: $border-radius;
            outline-offset: 2px;
        }
    }

    .user-utils {

        @include media-breakpoint-down(md) {
            align-items: center;

            // the ul
            .resource-menus {
                // the li
                .resource-menu__item {
                    &.dropdown-menu__mobile {
                        button:focus-visible {
                            outline: none;
                            box-shadow: inset 0 0 0 2px $slate;
                            border-radius: $border-radius;
                        }
                    }
                }
            }
        }

        // the ul
        .resource-menus {
            display: flex;
            align-items: center;
            flex-direction: row;
            list-style-type: none;
            padding-left: 0;
            margin-bottom: 0;

            // the li
            .resource-menu__item {
                height: 40px;
                list-style-type: none;

                // 'About Us', 'Help', 'Account' when signed in
                button.resource-menu__item-title {
                    border: none;
                    background-color: transparent;
                    color: $slate;

                    &:focus {
                        border: none;
                        outline: none;
                    }

                    &:focus-visible {
                        outline: none;
                        box-shadow: inset 0 0 0 2px $slate;
                        border-radius: $border-radius;
                    }
                }

                // 'Stores', 'Insider', 'Sign In/Join', 'Saved'(not signed in)
                // this is a button for ones with dropdowns; div otherwise
                .resource-menu__item-title {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    position: relative;
                    height: 100%;
                    width: 100%;
                    font-size: 12px;
                    padding: 0 15px;
                    color: $slate;
                    cursor: pointer;

                    // the non-dropdown-having ones
                    a {
                        text-decoration: none;

                        &:focus {
                            outline: none;
                        }

                        &:focus-visible {
                            outline: 2px solid $slate;
                            border-radius: $border-radius;
                            outline-offset: 3px;
                        }
                    }

                    &::after {
                        border: none;
                        content: '';
                        position: absolute;
                        top: auto;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 4px;
                        background: $yellow;
                        transform: translate(0, 5px);
                        transition: all 0.5s cubic-bezier(0.4, 0.01, 0.1, 1);
                    }

                    a:hover {
                        text-decoration: none;
                        border: none;
                        outline: none;
                    }
                }

                &:hover,
                &:focus-within {
                    .resource-menu__item-title::after {
                        transform: scale(1.1);
                    }
                }

                .dropdown-menu {
                    transition: all 0.5s cubic-bezier(0.4, 0.01, 0.1, 1);
                    overflow: hidden;
                    padding: 0;
                    border: none;
                    margin: 0;

                    ul {
                        font-size: 12px;
                        border: 1px solid $gray;
                        margin: 0;
                        padding: 20px;
                        list-style: none;
                        width: 170px;

                        li {
                            margin-bottom: 10px;
                            color: $gray-ada;

                            &:last-child {
                                margin: 0;
                            }

                            &:focus-within {
                                outline: 2px solid $slate;
                                border-radius: $border-radius;
                                outline-offset: 2px;
                            }

                            a {
                                text-decoration: none;

                                &:hover {
                                    // color: $blue;
                                    color: $black;
                                    text-decoration: underline;
                                }

                                &:focus,
                                &:focus-visible {
                                    outline: none;
                                }
                            }
                        }
                    }

                    &.resource-menu__item-account {
                        ul {
                            width: 140px;
                        }
                    }
                }
            }
        }
    }

    .user {
        position: relative;

        .popover {
            position: absolute;
            display: none;
            padding: 1em;
            top: 85%;
            left: 0;

            a {
                white-space: nowrap;
                margin-bottom: 0.5em;
            }

            &::before {
                left: 1.5rem;
            }

            &::after {
                left: 1.5rem;
            }

            &.show {
                display: block;
            }
        }
    }
}

.brand {
    position: absolute;
    left: 50%;
    display: block;
    text-align: center;

    img {
        width: 100%;
    }

    @include media-breakpoint-up(lg) {
        width: 14.125em; /* 226/16 */
        margin-left: -7.0625em; /* 113/16 */
        padding-top: 0.5em;
    }

    @include media-breakpoint-down(lg) {
        width: 4em; /* 64/16 */
        margin-left: -2em;
        padding-top: 0.8em;
    }
}

.main-menu {
    background-color: $white;
    border: 1px solid $gray;
    border-width: 1px 0;

    .row {
        @include media-breakpoint-up(lg) {
            height: 80px;
        }
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }

    .menu-group {
        height: 80px;
        @include media-breakpoint-down(md) {
            height: auto;
        }
    }

    .navbar {
        position: initial;
        flex-grow: 1;
    }

    .navbar-nav {
        height: 100%;
        justify-content: center;

        &.show {
            display: flex;
        }
    }

    li.nav-item {
        display: flex;
        height: 100%;
        align-items: center;
        position: initial;

        @include media-breakpoint-up(lg) {
            .nav-item__top-category::after {
                content: '';
                position: absolute;
                top: auto;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 4px;
                background: $yellow;
                transform: translate(0, 5px);
                transition: all 0.5s cubic-bezier(0.4, 0.01, 0.1, 1);
            }

            &:hover,
            &:focus-within {
                .nav-item__top-category::after {
                    transform: scale(1.5);
                }
            }

            .nav-item__top-category a {
                &:focus-visible {
                    outline: 2px solid $slate;
                    border-radius: $border-radius;
                    outline-offset: 5px;
                }
            }
        }

        &:focus {
            outline: none;
        }

        &.header-link {
            display: none;
        }

        @include media-breakpoint-down(md) {
            // here it contains an (accordion) button
            //    desktop it contains a regular div
            border-bottom: 1px solid $slate;
            height: 45px;

            // li.nav-item and...
            &.dropdown-menu__mobile {
                height: unset;
                flex-direction: column;

                > button:focus-visible,
                > a:focus-visible {
                    outline: none;
                    box-shadow: inset 0 0 0 2px $slate;
                    border-radius: $border-radius;
                }

                a:focus {
                    outline: none;
                }

                // sign in/join, insider, stores, etc.
                a.mobile-nav-item {
                    width: 100%;
                    text-decoration: none;

                    &.right-image-link {
                        width: 210px;
                    }
                }

                // mobile-layout: 'resource' <button>/<a>'s on main nav panel
                .dropdown-menu__mobile-title {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 45px;
                    width: 100%;
                    padding: 15px;
                    font-size: 16px;
                    background-color: transparent;
                    color: $black;
                    border: none;

                    span {
                        display: flex;
                        align-items: center;

                        .icon {
                            margin-right: 10px;

                            &.icon-leatherman {
                                background-image: url('../images/Leatherman_Logo_Icon.jpg');
                            }
                        }
                    }

                    .icon {
                        width: 20px;
                        height: 20px;
                    }

                    &:focus {
                        outline: none;
                    }
                }

                .dropdown-menu__mobile-list {
                    width: 100%;
                    border-top: 1px solid $slate;

                    ul {
                        list-style: none;
                        padding-inline-start: 50px;
                        padding-block-start: 20px;

                        li {
                            font-size: 16px;
                            margin-bottom: 25px;

                            a {
                                text-decoration: none;

                                &:focus-visible {
                                    outline: 2px solid $slate;
                                    border-radius: $border-radius;
                                    outline-offset: 3px;
                                }
                            }
                        }
                    }
                }

                &.first {
                    border-top: 1px solid $slate;
                }

                // accordions in main and sub navs (mobile)
                .collapsing {
                    @include transition(height 0.75s ease);
                }
            }

            &.header-link {
                display: flex;
                background-color: $slate;

                a {
                    display: block;
                    padding-left: 15px;
                    width: calc(100% - 15px);
                }
            }

            .header__country-selector,
            .nav-link {
                width: 100%;
            }

            button.nav-item__top-category.nav-link,
            a.nav-item__top-category.nav-link {
                background-color: transparent;
                border: none;

                h2,
                span.product-nav-button-title {
                    @include h4();

                    text-transform: uppercase;
                    padding-left: 10px;
                    margin-bottom: 0;
                    color: $slate;
                }

                &:focus {
                    outline: none;
                    border: none;
                }
            }

            .header__country-selector {
                font-size: 16px;

                .flag-icon {
                    margin-right: 10px;
                }
            }

            &.dropdown {
                height: 60px;
            }
        }
    }

    // MOBILE main panel product accordion buttons
    li.nav-item.dropdown-menu__mobile {
        > button:focus-visible,
        > a:focus-visible {
            outline: none;
            box-shadow: inset 0 0 0 2px $slate;
            border-radius: $border-radius;
        }

        a:focus {
            outline: none;
        }

        &.mobile-garage-link {
            a:focus-visible {
                outline: none;
                box-shadow: inset 0 0 0 2px $white;
                border-radius: $border-radius;
            }

            a:focus {
                outline: none;
            }
        }
    }

    .nav-item__top-category {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;

        a {
            font-size: 20px;
            font-family: $brand-font-condensed;
            text-transform: uppercase;

            &:hover {
                text-decoration: none;
                color: $slate;
            }

            &:focus {
                outline: none;
            }
        }

        img {
            width: 50px;
        }

        .icon-arrow-right {
            width: 20px;
            height: 20px;
        }

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            &:focus {
                outline: none;
            }

            a {
                font-size: 25px;
                padding-left: 10px;
            }

            span {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }
    }

    .dropdown-toggle::after {
        border: none !important;
    }

    .close-menu {
        background-color: $white;
        padding: 10px;
    }

    .navbar .close-menu button,
    .navbar .close-button button {
        background-color: transparent;
        border: 0;
        -webkit-appearance: none;
        padding: 10px;

        .icon {
            width: 20px;
            height: 20px;
        }

        &:focus-visible {
            outline: 2px solid $slate;
            border-radius: $border-radius;
            outline-offset: 2px;
        }
    }

    .navbar-nav.show .dropdown-menu {
        height: 370px;
        @include media-breakpoint-down(md) {
            height: 100%;
        }
    }

    .dropdown-menu {
        top: 120px;
        width: 100%;
        height: 0;
        padding: 0;
        transition: height 0.5s cubic-bezier(0.4, 0.01, 0.1, 1);
        position: relative;
        display: block;
        z-index: -1;
        box-shadow: none;
        overflow: hidden;

        .droppdown-menu__content {
            background: $white;
            width: 100%;
            opacity: 0;
            display: flex;
            padding-top: 26px;
            justify-content: center;
            transition: all 0.5s cubic-bezier(0.4, 0.01, 0.1, 1);
        }

        @include media-breakpoint-down(md) {
            padding: 0;
            margin: 0;
            position: absolute;
            right: -100%;
            left: auto;
            top: 0;
            width: 100%;
            height: 100%;
            border: 0 none;
            transition: $slide-out-animation;
            display: block;
            overflow: scroll;

            .droppdown-menu__content {
                opacity: 1;
                padding: 0;
            }
        }

        &.show {
            z-index: 2147483647;
            box-shadow: 0 3px 5px rgba(43, 36, 25, 0.4);

            .droppdown-menu__content {
                opacity: 1;
            }

            @include media-breakpoint-down(md) {
                display: block;
                height: auto;
                min-height: 100%;
                box-shadow: none;

                .droppdown-menu__content {
                    display: block;
                    height: 100%;
                }
            }
        }
    }

    .dropdown-menu__subcat-title-category {
        margin-left: 20px;
        margin-bottom: 10px;
        text-transform: uppercase;
        letter-spacing: 0.3px;
        color: $black;
    }

    .dropdown-menu__subcat-title-categories,
    .dropdown-menu__subcat-category-name {
        padding: 0 20px;
        height: 60px;
        display: flex;
        align-items: center;
        color: $black;
        text-transform: uppercase;
        font-family: $brand-font-condensed;
        margin-bottom: 0;
    }

    button.dropdown-menu__subcat-title-categories {
        width: 100%;
        background-color: transparent;
        border: none;
        border-top: 1px solid $slate;

        h3,
        span.category-button-title {
            @include h5();
        }

        &:focus {
            outline: none;
        }

        &:focus-visible {
            outline: none;
            box-shadow: inset 0 0 0 2px $slate;
            border-radius: $border-radius;
        }
    }

    .dropdown-menu__subcat-category-name {
        border-top: 1px solid $slate;
        font-size: 25px;
    }

    .dropdown-menu__subcat-title-categories {
        flex-direction: row;
        justify-content: space-between;
        border-top: 1px solid $slate;
        border-width: 1px 0;
        text-transform: uppercase;
        font-size: 20px;

        .icon {
            width: 20px;
            height: 20px;
        }
    }

    .drowndown-menu__dropdown-items {
        @include media-breakpoint-down(md) {
            border-top: 1px solid $slate;
            background-color: $blue-v1;
        }

        a:hover {
            color: $gray-ada;
        }

        a:focus-visible {
            outline: 2px solid $slate !important;
            outline-offset: 4px !important;
            border-radius: $border-radius !important;
        }
    }

    .dropdown-menu__mobile-image {
        display: flex;
        flex-direction: row;

        img {
            width: 50px;
        }
    }

    .dropdown-item {
        padding-bottom: 10px;

        + .dropdown-item {
            border: none;
        }

        &:hover,
        &:active,
        &:focus {
            background-color: $white;
        }

        @include media-breakpoint-down(md) {
            padding: 0 25px 10px;

            &:first-child {
                padding-top: 12px;
            }

            &:last-child {
                padding-bottom: 20px;
            }

            &:hover,
            &:active,
            &:focus {
                background-color: transparent;
            }
        }

        .dropdown-link {
            display: flex;
            align-items: center;
            font-size: 20px;
            font-family: $brand-font-condensed;
            color: $slate;
            text-decoration: none;
            text-transform: uppercase;
            outline: none;
            padding: 0;

            .dropdown-menu__subcat-image img {
                width: 60px;
                border-radius: 50%;
                border: 1px solid $slate;
            }

            span {
                margin-left: 15px;
            }

            &:focus {
                outline: none;
            }

            &:focus-visible {
                outline: 2px solid $slate !important;
                border-radius: $border-radius !important;
                outline-offset: 4px !important;
            }

            @include media-breakpoint-up(lg) {
                &:hover {
                    .dropdown-menu__subcat-image img {
                        // border-color: $blue;
                        border-color: $gray-ada;
                        border-width: 3px;
                    }

                    // color: $blue;
                    color: $gray-ada;
                }
            }
        }
    }

    .dropdown-menu__main {
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        margin-top: 20px;
        margin-right: 50px;

        @include media-breakpoint-down(lg) {
            margin-right: 10px;
        }

        &:hover {
            // color: $blue;
            color: $gray-ada;
        }

        &:focus {
            outline: none;
        }

        &.desktop-layout:focus-visible {
            outline: 2px solid $slate;
            border-radius: $border-radius;
            outline-offset: 2px;
        }
    }

    .dropdown-menu__main-image {
        width: 250px;

        @include media-breakpoint-down(md) {
            display: none;
        }

        img {
            width: 100%;
        }
    }

    .dropdown-menu__content-right {
        display: flex;
        flex-direction: row;
        margin-left: 25px;

        @include media-breakpoint-down(lg) {
            margin-left: 15px;
        }

        @include media-breakpoint-down(md) {
            display: block;
            margin: 0;
        }
    }

    .dropdown-menu__subcat-title-category,
    .dropdown-menu__content-right-title {
        display: flex;
        text-transform: uppercase;
        color: $black;
        @include h6();

        @include media-breakpoint-down(md) {
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0 20px;
            background-color: transparent;
            border: none;

            h3 {
                @include h5();
            }

            .icon {
                width: 20px;
                height: 20px;
            }
        }
    }

    button.dropdown-menu__content-right-title {
        height: 60px;

        span.content-right-button-title {
            @include h5();
        }

        &:focus {
            outline: none;
        }

        &:focus-visible {
            outline: none;
            box-shadow: inset 0 0 0 2px $slate;
            border-radius: $border-radius;
        }
    }

    .dropdown-menu__content-right-list {
        margin-left: 25px;
        width: 150px;

        @include media-breakpoint-down(lg) {
            margin-left: 15px;
            width: 120px;
        }

        @include media-breakpoint-down(md) {
            width: auto;
            margin: 0;
            border-top: 1px solid $slate;
        }

        ul {
            list-style-type: none;
            padding: 0;

            @include media-breakpoint-down(md) {
                border-top: 1px solid $slate;
                background-color: $blue-v1;
            }

            li {
                font-size: 14px;

                @include media-breakpoint-down(md) {
                    font-size: 20px;
                    font-family: $brand-font-condensed;
                    color: $slate;
                    text-decoration: none;
                    text-transform: uppercase;
                    outline: none;
                    padding: 0 25px 10px 40px;

                    &:first-child {
                        margin-top: 12px;
                    }

                    &:last-child {
                        padding-bottom: 20px;
                    }
                }

                a {
                    text-decoration: none;
                    color: $slate;

                    @include media-breakpoint-down(md) {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                    &:focus {
                        outline: none;
                    }

                    &:focus-visible {
                        outline: 2px solid $slate !important;
                        border-radius: $border-radius !important;
                        outline-offset: 3px !important;
                    }

                    @include media-breakpoint-up(md) {
                        line-height: 1.79;

                        &:hover {
                            // color: $blue;
                            color: $gray-ada;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .dropdown-menu__content-right-image {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(md) {
            flex-direction: row;
            border-top: 1px solid $slate;
        }
    }

    .dropdown-menu__content-right-image a {
        display: flex;
        flex-direction: column;
        font-size: 15px;
        font-family: $brand-font-condensed;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: 0.3px;
        color: $slate;
        text-decoration: none;
        margin-left: 40px;

        @include media-breakpoint-down(lg) {
            margin-left: 30px;
            width: 210px;
        }

        @include media-breakpoint-down(md) {
            margin-left: 20px;
            margin-top: 20px;
            width: 210px;
        }

        &:hover {
            // color: $blue;
            color: $gray-ada;
            text-decoration: underline;
        }

        &:focus-visible {
            outline: 3px solid $slate;
            outline-offset: 3px;
        }

        img {
            margin-bottom: 15px;
            width: 210px;
            height: 120px;
        }

        span {
            width: 210px;
        }
    }
}

.header-banner {
    background-color: $slate;
    text-align: center;
    color: $white;
    height: 45px;
    position: relative;

    &.holiday {
        background-color: #c54d42;
        overflow: hidden;
    }

    /* stylelint-disable at-rule-no-unknown */
    @function random_range($min, $max) {
        $rand: random();
        $random_range: $min + floor($rand * (($max - $min) + 1));
        @return $random_range;
    }

    .snow {
        $total: 40;

        position: absolute;
        width: 10px;
        height: 10px;
        background: white;
        border-radius: 50%;
        margin-left: 3vw;

        @for $i from 1 through $total {
            $random-x: random(940000) * 0.0001vw;
            $random-offset: random_range(-30000, 30000) * 0.0001vw;
            $random-x-end: $random-x + $random-offset;
            $random-x-end-yoyo: $random-x + ($random-offset / 2);
            $random-yoyo-time: random_range(30000, 80000) / 100000;
            $random-yoyo-y: $random-yoyo-time * 45px;
            $random-scale: random(10000) * 0.0001;
            $fall-duration: random_range(3, 9) * 1s;
            $fall-delay: random(30) * 1s;

            &:nth-child(#{$i}) {
                opacity: random(10000) * 0.0001;
                transform: translate($random-x, -10px) scale($random-scale);
                animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
            }

            @keyframes fall-#{$i} {
                #{percentage($random-yoyo-time)} {
                    transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
                }

                to {
                    transform: translate($random-x-end-yoyo, 45px) scale($random-scale);
                }
            }
        }
    }

    .container-fluid {
        max-width: 480px;
        padding: 2px 10px 0;
    }

    #headerPromoBarControls {
        margin-top: 10px;

        .carousel-inner {
            padding-inline-start: 0;
            width: auto;
            margin: 5px 20px;

            .carousel-item {
                margin-right: -100%;

                a {
                    color: $white;
                    text-decoration: underline;
                    padding: 5px;

                    &:focus-visible {
                        outline: 3px solid $white;
                    }

                    // a Level Access specific width
                    $xs-ada: 325px;
                    @media (max-width: $xs-ada) {
                        font-size: 12px;
                    }
                }
            }
        }

        .carousel-control-next.icon-arrow-right,
        .carousel-control-prev.icon-arrow-left {
            opacity: 1;
            border: none;
            background-color: transparent;
            filter: invert(1);
            width: 20px;
            height: 20px;
            padding: 10px 0;

            &:focus {
                outline: none;
            }

            &:focus-visible {
                outline: 3px solid $black;
                outline-offset: 2px;
            }

            $xs-ada: 325px;
            @media (max-width: $xs-ada) {
                width: 14px;
                height: 14px;
            }
        }
    }

    a {
        color: $white;
        text-decoration: underline;

        &:focus-visible {
            outline: 2px solid $black;
            outline-offset: 2px;
        }
    }

    .content {
        padding: $banner-padding 0;
    }

    .header-link {
        position: absolute;
        z-index: 2;

        a {
            display: block;
            padding: 10px 30px;
            height: 45px;
            border-right: 1px solid $white;

            &:focus {
                outline: none;
            }

            &:focus-visible {
                outline: none;
                box-shadow: inset 0 0 0 2px $white;
                border-radius: $border-radius;
            }
        }

        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}

.minicart {
    margin-top: 6px;
    vertical-align: top;

    .icon-cart {
        width: 28px;
        height: 28px;
        margin: 0 10px;
    }

    a.minicart-link {
        border: none;
        background-color: transparent;

        &:hover {
            text-decoration: none;
        }

        &:focus {
            outline: none;
        }

        &:focus-visible {
            outline: 3px solid $slate;
            border-radius: $border-radius;
            outline-offset: 3px;
        }
    }
}

a.normal {
    color: $primary;
    text-decoration: underline;
}

.slide-up {
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
    max-height: 100px;
    overflow: hidden;

    &.hide {
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
}

.dropdown-country-selector {
    margin-top: -0.0625em;
}

.cookie-warning-messaging.cookie-warning {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    display: none;
}

.valid-cookie-warning {
    background-color: $blue;
    color: $white;
    white-space: nowrap;

    p {
        margin-top: 0;
        margin-bottom: 0.2em;
        padding-right: 2em;
    }
}

#newsletterPopupModal .modal-dialog {
    max-width: 960px;

    .modal-header {
        padding: 5px;
        z-index: 1;
        position: relative;
    }

    .modal-body {
        padding: 0 10px 10px;
    }
}

.newsletter-popup {
    display: flex;
    padding-top: 30px;

    .newsletter-popup__left-image {
        img {
            width: 400px;
            height: 400px;
        }
    }

    &__right-content {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        padding: 0 40px;
        width: 100%;

        @include media-breakpoint-down(md) {
            padding: 0;
        }

        .confirmation-message {
            text-align: center;

            h4 {
                margin-top: 20px;
            }
        }

        .dpc {
            text-align: left;

            .dpc-copy p a {
                color: $slate;
            }
        }

        .privacy-copy {
            text-align: left;
        }
    }
}

#countrySelector {
    z-index: 1080;
}
