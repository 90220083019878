@import "variables";

.section-yotpo-homepage {
    letter-spacing: 0;
    background: $blue-v1;
    color: $slate;
    padding: 60px 40px 0;

    .section-yotpo-homepage-inner {
        max-width: 1500px;
        margin: auto;
        padding-bottom: 5px;
    }

    .section-yotpo-title {
        @include h4();

        text-align: center;
        font-size: 30px;
    }

    .section-yotpo-subtitle {
        @include text-body();

        color: $slate;
        text-align: center;
        margin-bottom: 20px;
    }

    .yotpo-load-more-button {
        @include button();
        @include button-primary();

        font-size: $lg-font-size;
        padding: 10px 20px !important;
        color: $slate;
        background: $yellow;
    }

    .yotpo-pictures-gallery .load-btn-white,
    .yotpo-pictures-widget .load-btn-white {
        background: $yellow;
        @include media-breakpoint-down(sm) {
            margin-top: 25px;
        }
    }

    .yotpo-pictures-widget {
        margin-top: 0;
        margin-bottom: 0;

        .yotpo-pictures-gallery-header-wrapper {
            margin-bottom: 0;
        }

        .yotpo-default-button {
            margin: 20px auto;

            @include button();
            @include button-primary();
        }
    }

    .y-image-overlay,
    .yotpo-visual-carousel .y-image-overlay,
    .yotpo-pictures-widget .y-image-overlay {
        border-radius: 0;
    }

    .y-image-overlay {
        transition: all 0.25s;
    }

    @include media-breakpoint-down(md) {
        padding: 40px 0 0;

        .section-yotpo-subtitle {
            padding: 0 5%;
        }

        .yotpo-pictures-widget .yotpo-pictures-gallery-wrapper {
            width: 90%;
            margin: auto;
        }
    }
}

.yotpo-lightbox.yotpo .yotpo-display-wrapper .yotpo-modal.yotpo-modal-active .yotpo-lightbox-container {
    .yotpo-lightbox-content-container {
        border-radius: 0;

        .yotpo-lightbox-products-container {
            .yotpo-lightbox-product-name {
                @include h4();

                font-size: 32px;
                margin-top: 8px;
                font-weight: 400;

                @include media-breakpoint-down(md) {
                    font-size: 24px;
                }
            }

            .yotpo-lightbox-product-button {
                @include button();
                @include button-primary();

                color: $slate;
                padding: 10px 20px !important;
                min-width: 90px;
                margin-top: 63px;
                border-radius: 0;
                font-weight: normal;
            }
        }

        .yotpo-instagram {
            .yotpo-header {
                padding-bottom: 20px;
            }

            .yotpo-icon-profile {
                background: $blue;
            }

            .yotpo-user-name {
                color: $slate;
                font-family: $trade-gothic-bold;
                font-size: 15px;
            }

            .yotpo-regular-box {
                color: $slate;

                .yotpo-instagram-hashtag {
                    color: $blue;
                }
            }

            .y-label.yotpo-review-date {
                color: $slate;
                font-size: 11px;
            }

            .yotpo-lightbox-image {
                border-radius: 0;
            }

            @include media-breakpoint-down(sm) {
                position: relative;

                .yotpo-header {
                    position: unset;

                    .yotpo-review-date {
                        top: auto !important;
                        bottom: -25px;
                        left: 12px;
                    }
                }

                .yotpo-regular-box {
                    width: 100% !important;
                }
            }
        }
    }

    .yotpo-lightbox-slider-container {
        .yotpo-modal-dialog .yotpo-modal-content {
            .yotpo-lightbox-image {
                border-radius: 0;
                max-width: 100%;
                max-height: 100%;
            }

            .yotpo-lightbox-image {
                vertical-align: top;
            }
        }

        .yotpo-lightbox-arrows .yotpo-icon-left-arrow-thin,
        .yotpo-lightbox-arrows .yotpo-icon-right-arrow-thin {
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    @include media-breakpoint-down(md) {
        width: 90%;

        .yotpo-lightbox-slider-container,
        .yotpo-lightbox-content-container {
            width: 100%;
        }

        .yotpo-lightbox-slider-container .yotpo-icon-btn-small .yotpo-icon.yotpo-icon-cross {
            right: 0;
            top: -20px;
        }
    }
}

#maincontent .product-detail.product-wrapper {
    .yotpo {
        div,
        span,
        p,
        a,
        img,
        strong,
        sup,
        ul,
        li,
        form,
        label {
            font-family: $body-font;
            font-weight: normal;
        }

        &#yotpo-reviews-top-div.yotpo-main-widget .main-widget {
            input:not([type='button']),
            textarea {
                background-color: $white;
                border: 1px solid $slate;
            }
        }

        .y-label,
        span.y-label {
            color: $slate;
        }

        input[type="button"].yotpo-default-button,
        .yotpo-default-button:not([data-network]) {
            @include button();
            @include button-primary();

            border-radius: 0;
            height: auto;
            padding: 15px 30px !important;
            letter-spacing: 0.3px;
            color: $slate;
            font-size: 15px;
            font-weight: normal;

            &:hover {
                background-color: $slate !important;
                color: $white;

                .desktop-clear-btn-text {
                    background-color: $slate;
                    color: $white;
                    transition: 0.25s;
                }
            }

            /* stylelint-disable selector-pseudo-class-no-unknown */
            &:focus-visible,
            &:active {
                box-shadow: inset 0 0 0 2px $slate;
                outline: none;
                border: none;
            }

            .desktop-clear-btn-text {
                font-family: $trade-gothic-bold-condensed;
                color: $slate;
                font-size: 15px;
            }

            &.yotpo-disabled,
            &.yotpo-disabled:hover {
                color: $slate;
                background-color: $slate-v1;

                &:hover {
                    color: $slate;
                    background-color: $slate-v1;
                }

                // pseudo-class
                &:disabled {
                    color: $slate;
                    background-color: $slate-v1;

                    &:hover {
                        color: $slate;
                        background-color: $slate-v1;
                    }
                }
            }
        }

        .write-question-review-button-text {
            font-family: $trade-gothic-bold-condensed;
            letter-spacing: 0.3px;
            color: $slate;
            font-size: 15px;
            font-weight: normal;
        }

        .yotpo-drop-down-layout {
            .filters-dropdown {
                width: 20%;
                margin: 0 !important;
                padding: 5px;

                // help text not get cut off for the long one (images & videos)
                //    plus look more even
                @include media-breakpoint-down(md) {
                    width: 23%;
                }

                @include media-breakpoint-down(sm) {
                    width: 25%;
                }
            }
        }

        .dropdown-filters-container {
            display: flex;
            flex-wrap: wrap;
            // dropdowns would butt up against each other, and
            // overlap at <992px viewport sizes. it looked ridiculous.
            gap: 2px;
            clear: both;
            margin-left: -10px;
            margin-right: -10px;
        }

        .yotpo-dropdown-button {
            border-radius: 0;
            border: 1px solid $slate;
            color: $slate;
            height: 36px;
            padding: 6px 10px 0 15px;
            width: 100%;
            // the arrow/chevrons went outside of their containers at
            // <992px viewport sizes. it looked even more ridiculous.
            // min-width: 177px;
            min-width: 80%;
            margin: 0;

            .selected,
            span:not(.yotpo-icon),
            .non-default-item-selected {
                width: 80%;
                color: $slate !important;
                font-size: 14px;
                font-weight: normal;
                padding-left: 0;
                line-height: 1.54em;
            }

            .yotpo-icon-down-triangle {
                float: right;
            }

            &:focus-visible {
                box-shadow: inset 0 0 0 3px $slate;
                outline: none;
                border: none;
            }
        }

        .yotpo-dropdown.list-categories {
            border: 1px solid $slate;
            border-top: none;
            border-radius: 0;
            width: calc(100% - 20px);
            left: 10px;

            .list-category {
                a.non-selected-item {
                    color: $slate !important;
                    font-size: 14px;
                }
            }
        }

        .sort-drop-down .yotpo-dropdown-button {
            width: 100%;
        }

        .yotpo-reviews-header {
            padding: 10px 0;

            .yotpo-nav-dropdown.sorting {
                .yotpo-dropdown.list-categories {
                    left: 0;
                }
            }
        }

        .yotpo-icon-down-triangle {
            padding: 0;

            &::before {
                content: '';
                background: url(../images/ui/icon_arrow_down.svg) no-repeat center 5px;
                background-size: contain;
                width: 20px;
                height: 20px;
                display: inline-block;
            }
        }
    }

    #review-terms-privacy,
    #review-required,
    #review-share-message {
        display: none;
    }

    .yotpo-shoppers-say {
        clear: none;
        padding: 0 1.4em;

        @include media-breakpoint-down(md) {
            margin-top: 40px;
        }

        .shoppers-say-title {
            font-size: 20px;
            font-family: $brand-font;
            font-weight: normal;
            display: inline-block;
            width: 100%;
        }

        .sentence-container {
            .review-title {
                font-family: $body-font;
                letter-spacing: 0.01em;
            }

            .full-review {
                font-family: $body-font;
                letter-spacing: 0.5px;
            }

            .sentence {
                font-family: $body-font;
                font-size: 14px;
            }

            .display-name {
                font-size: 14px;
                font-family: $body-font;
            }

            .single-sentence-text-content {
                margin-top: 12px;
            }

            &.no-review-background {
                padding-top: 12px;
            }

            &.full-review-background {
                background: $white;
                padding: 12px 0 20px;
                margin-bottom: 0;
            }
        }

        .more-reviews {
            font-family: $brand-font;
            text-decoration: underline !important;
            font-size: 16px;
            color: $slate !important;
            font-weight: normal;
            letter-spacing: 0.5px;

            &:hover {
                color: $slate !important;
            }
        }

        .shoppers-say-display-wrapper {
            width: 100%;

            .yotpo-regular-box.no-border-top {
                padding-top: 0;
            }
        }

        .yotpo-icon-down-arrow,
        .yotpo-icon-up-arrow {
            color: $blue !important;
            font-size: 30px;
        }

        @include media-breakpoint-only(md) {
            float: none;
            width: 100%;
            clear: both;
            padding: 20px 1.5em 0 0;
        }

        @include media-breakpoint-down(md) {
            float: none;
            width: 100%;
            clear: both;
            padding: 1px 0 0;

            .shoppers-say-title {
                margin-top: -15px;
            }
        }
    }

    #yotpo-bottomline-top-div {
        .yotpo-bottomline.pull-left.star-clickable {
            .text-m {
                font-size: $sm-font-size;
                margin: 0 7px;
                color: $slate !important;
                font-family: $brand-font;
                text-transform: uppercase;

                &:hover {
                    // right now these go nowhere on product tiles; (see 'cursor' just above)
                    // making them appear they do is not accessible; they get tabindex="-1" on tiles
                    // color: $gray-ada !important;
                }

                &:focus-visible {
                    // they do go somewhere on PDP's though, and are focusable
                    outline: 2px solid $slate;
                    border-radius: $border-radius;
                    outline-offset: 3px;
                }
            }
        }
    }

    .yotpo-bottomline.bottom-line-items-container {
        padding-top: 45px;
    }

    .yotpo-pictures-widget {
        margin: 50px 0 0;

        .yotpo-pictures-gallery-header-wrapper {
            margin-bottom: 12px;
        }

        .yotpo-slider-wrapper,
        .yotpo-pictures-gallery-wrapper {
            margin: 0 auto;
            padding-bottom: 55px;

            .yotpo-slider-title-wrapper,
            .yotpo-pictures-gallery-title-wrapper {
                padding-bottom: 0;
                margin-bottom: 0;
                text-align: left;

                .yotpo-slider-title,
                .yotpo-pictures-gallery-title {
                    color: $slate;
                    font-family: $trade-gothic-bold-condensed !important;
                    @include h3();
                }
            }

            .yotpo-display-wrapper .yotpo-single-image-container .yotpo-image {
                border-radius: 0;
                min-height: 220px;
            }

            .yotpo-single-image-container .y-image-overlay {
                transition: all 0.25s;
            }

            .yotpo-single-image-container .y-image-overlay:hover {
                background-color: $blue;
                opacity: 0.8;
                border-radius: 0;
            }

            @include media-breakpoint-down(md) {
                margin: 0 auto 20px;
            }
        }

        .yotpo-load-more-button {
            @include button();
            @include button-primary();

            padding: 10px 20px !important;
            margin-top: 5px;
        }
    }
}

.product-detail.product-wrapper .reviews-list {
    &.facile-tab-wrapper {
        .facile-tab-content .active {
            border-bottom: none;
        }

        @include media-breakpoint-up(md) {
            border-top: 1px solid $slate;
            padding-top: 15px;
            margin-top: 20px;

            .facile-tab.reviews-tab {
                display: none;
            }

            #pr-reviews-tab.facile-tab-content {
                border-top: none;
                padding-top: 0;
            }
        }
    }

    .reviews-tab {
        font-size: 32px;
    }

    .tab-separator {
        margin-bottom: -5px;
        height: 32px;
        display: inline-block !important;
    }

    #pr-reviews-tab {
        padding-top: 20px;
    }
}

#yotpo-reviews-top-div.yotpo-main-widget {
    border-top: 1px solid $gray;

    .yotpo a,
    .yotpo div,
    .yotpo label,
    .yotpo span {
        font-family: $body-font;
    }

    .main-widget {
        .avg-score,
        .yotpo-label-container {
            display: none;
        }

        .aggregated-product-related-fields {
            margin-bottom: 10px;

            .product-related-fields-item-title {
                width: 99px !important;
                text-align: right;
            }

            .text-s {
                color: $slate;
            }

            .yotpo-rating-bar-full {
                background-color: $blue;
            }
        }

        .new-yotpo-small-box {
            border-bottom: 0;
        }

        .rating-stars-container {
            padding-left: 0;
            @include media-breakpoint-down(md) {
                .rating-star {
                    font-size: 26px;
                    margin-bottom: 10px;
                }
            }
        }

        .reviews .yotpo-nav-primary {
            border-bottom: 1px solid $slate;
        }

        .reviews-qa-labels-container {
            display: block;
            padding-top: 2px;

            .reviews-qa-label {
                font-family: $brand-font;
                font-size: 30px;
                color: $slate;
                text-transform: uppercase;
            }
        }

        .suggested-topics .suggested-topics-row {
            float: left;

            .suggested-topic {
                &:focus-visible {
                    box-shadow: inset 0 0 0 3px $slate;
                    outline: none;
                    border: none;
                }
            }
        }

        .terms {
            color: $slate;
            font-family: $body-font;
            letter-spacing: 0.5px;
            margin-bottom: 10px;

            a {
                text-decoration: underline !important;
                font-family: $body-font;
            }
        }

        .write-question-review-buttons-container {
            float: none;
            margin: auto;
            padding-right: 0 !important;
            text-align: center;

            .yotpo-icon-btn {
                .yotpo-icon {
                    display: none;
                }

                &:hover {
                    background: $slate !important;

                    span {
                        color: $white;
                    }
                }

                @include media-breakpoint-down(sm) {
                    display: block;
                    margin: 0 auto 20px;
                    margin-left: auto !important;
                    width: 72%;
                }
            }
        }

        .write-question-wrapper .form-element .y-label::after {
            content: '';
            width: 5px;
            height: 5px;
            display: inline-block;
            border-radius: 50%;
            margin-bottom: 7px;
            margin-left: 1px;
        }

        // WEB-10388: match styling of 'Reviews|Questions' tabs, to 'tools|features|specs' tabs
        .yotpo-nav.yotpo-nav-primary {
            ul[role=tablist] {
                overflow: hidden;

                .yotpo-nav-tab {
                    margin-right: 40px;
                    margin-bottom: 1px;
                    padding: 3px 3px 0;

                    .yotpo-nav-wrapper {
                        span {
                            @include h3();

                            transition: all 0.5s;

                            &::after {
                                content: '';
                                position: absolute;
                                top: auto;
                                bottom: -4px;
                                left: 0;
                                width: 100%;
                                height: 4px;
                                background: $yellow;
                                transform: translate(0, 4px);
                                transition: all 1s cubic-bezier(0.4, 0.01, 0.1, 1);
                            }

                            &:hover,
                            &:focus {
                                outline: none;

                                &:not(.nav-link.active) {
                                    color: $slate;
                                }

                                &::after {
                                    transform: scaleY(0.9) translateY(1px);
                                }
                            }
                        }
                    }

                    &.yotpo-active {
                        .yotpo-nav-wrapper {
                            border-bottom: none;

                            span {
                                color: $black;

                                &::after {
                                    transform: scaleY(1.2);
                                }

                                &:hover,
                                &:focus {
                                    outline: none;

                                    &:not(.nav-link.active) {
                                        color: $slate;
                                    }

                                    &::after {
                                        transform: scaleY(0.5) translateY(2px);
                                    }
                                }
                            }
                        }
                    }

                    &:focus-visible {
                        box-shadow: inset 0 0 0 2px $slate;
                        border-radius: $border-radius;
                        outline: none;
                        border: none;
                    }

                    &:not(.yotpo-active):hover span {
                        color: $gray-ada;
                    }
                }
            }
        }

        .visible {
            margin-top: 45px;
            font-family: $body-font;
            color: $slate;

            .write-review .yotpo-header {
                margin-bottom: 0;
            }

            .write-review-content {
                padding-top: 20px;

                .form-group {
                    display: block;

                    .form-element {
                        clear: both;

                        .yotpo-multiple-choice-field {
                            padding-top: 5px;

                            .yotpo-multiple-choice-option .yotpo-checkbox-wrapper {
                                margin-bottom: 10px;
                            }
                        }

                        .yotpo-open-text-field {
                            max-width: 400px;

                            textarea {
                                height: 32px;
                                padding: 0 5px;
                                line-height: 28px;
                                font-family: $body-font;
                                resize: none;
                            }

                            .text-length-counter {
                                display: none;
                            }
                        }
                    }

                    div:nth-child(-n+5) {
                        &.yotpo-custom-tag-field {
                            float: left;
                            width: 30%;
                            margin-left: 5%;
                            clear: none;

                            .yotpo-rating-field-bars {
                                display: none;
                            }

                            @include media-breakpoint-down(lg) {
                                width: 100%;
                                float: none;
                                margin-left: 0;
                            }
                        }
                    }

                    div:nth-child(3) {
                        &.yotpo-custom-tag-field {
                            margin-left: 0;
                        }
                    }
                }
            }

            .yotpo-regular-box {
                border-top: 1px solid $gray;

                .error-box {
                    margin: 0 0 10px;
                    background: none;

                    .text-wrapper {
                        padding: 0;

                        label,
                        span {
                            color: $red;
                            text-transform: none;
                            font-family: $body-font;
                            font-weight: normal;
                        }
                    }

                    .yotpo-icon-btn-small {
                        display: none;
                    }
                }

                .yotpo-footer {
                    display: inline-block;
                    width: 100%;
                    margin-top: 0;

                    .connect-wrapper {
                        padding-left: 0;
                        width: 70%;
                        display: inline-block !important;
                        margin-top: 0;

                        .form-element {
                            opacity: 1;
                            display: inherit;
                            margin-top: 0;

                            .y-label {
                                padding: 0;
                            }
                        }

                        @include media-breakpoint-down(sm) {
                            width: 100%;
                        }
                    }

                    .socialize-wrapper {
                        display: block;
                        float: left;
                        width: 20%;
                        position: relative;
                        margin-top: 0;

                        .socialize {
                            min-width: 120px;
                        }

                        .yotpo-default-button {
                            background: none;
                            float: left;
                            padding: 0 10px 0 0;
                            margin-top: -3px;

                            &[data-network="linkedin"] {
                                display: none;
                            }

                            .yotpo-icon {
                                font-size: 40px;
                            }

                            .yotpo-icon-facebook,
                            .yotpo-icon-twitter {
                                background-color: $slate;
                                border-radius: 50%;
                                width: 33px !important;
                                height: 33px;
                                font-size: 23px;

                                &::before {
                                    text-align: center;
                                    display: block;
                                    line-height: 33px;
                                }
                            }
                        }

                        .yotpo-or {
                            margin-top: 32px;
                            color: $slate;
                            font-family: $brand-font;
                            font-size: $lg-font-size;
                            width: 20%;
                        }

                        .y-label {
                            padding: 0 0 8px;
                        }

                        @include media-breakpoint-down(md) {
                            width: 23%;

                            .socialize {
                                min-width: 100px;
                            }
                        }

                        @include media-breakpoint-down(sm) {
                            width: 100%;

                            .socialize {
                                margin-bottom: 0;

                                > div {
                                    margin-left: 10px;
                                }
                            }

                            .yotpo-or {
                                margin: 0 0 10px;
                                width: 100px;
                            }
                        }
                    }
                }

                .yotpo-header {
                    .y-label {
                        padding-bottom: 5px;

                        &.yotpo-header-title {
                            font-size: $heading-3-font-size;
                            font-family: $brand-font;
                            margin: 10px 0 35px;
                            padding: 0;
                        }
                    }
                }

                .y-input {
                    background: $slate-v2;
                    border: none;
                    margin: 8px 0 30px;
                    font-family: $body-font;

                    &.error {
                        border: 1px solid $red;
                    }
                }

                .y-label {
                    font-family: $body-font;
                    font-size: $base-font-size;
                    text-transform: unset;
                    color: $slate;
                    display: inline-block;
                    position: relative;
                    min-width: unset;
                    width: unset;
                    white-space: normal;

                    &.yotpo-header-title {
                        font-size: $heading-3-font-size;
                        font-family: $brand-font;
                        margin: 10px 0 35px;
                        padding: 0;
                    }

                    .yotpo-mandatory-mark {
                        position: absolute;
                        display: block;
                        left: -11px;

                        &::after {
                            content: '';
                            width: 5px;
                            height: 5px;
                            display: inline-block;
                            border-radius: 50%;
                        }

                        @include media-breakpoint-down(sm) {
                            right: -10px;
                        }
                    }

                    &.yotpo-field-title {
                        margin-bottom: 10px;
                    }

                    &.y-label-required {
                        float: right;
                        margin: 50px 0 0;
                        padding: 0;

                        .yotpo-mandatory-mark {
                            right: auto;
                            left: -8px;
                        }
                    }
                }

                .yotpo-rating-field {
                    margin-top: 4px;

                    .y-label {
                        display: block;
                        color: $slate;
                    }

                    .yotpo-rating-field-content .yotpo-rating-field-buttons .yotpo-radio-wrapper input[type="radio"] {
                        border: 1px solid $gray !important;
                    }
                }

                .yotpo-rating-bar-full {
                    background-color: $blue;
                }

                .yotpo-single-choice-field {
                    padding-top: 4px;
                    padding-bottom: 0;
                    clear: both;

                    .yotpo-single-choice-option .yotpo-radio-wrapper input[type="radio"] {
                        border: 1px solid $gray !important;
                    }
                }

                .yotpo-submit {
                    @include button();
                    @include button-primary();

                    border: none;
                    float: none;
                    color: $slate;
                    min-width: 116px;
                    padding: 10px 20px !important;

                    &.yotpo-disabled,
                    &.yotpo-disabled:hover {
                        color: $slate;
                        background-color: $slate-v1;

                        &:hover {
                            color: $slate;
                            background-color: $slate-v1;
                        }

                        // pseudo-class
                        &:disabled {
                            color: $slate;
                            background-color: $slate-v1;

                            &:hover {
                                color: $slate;
                                background-color: $slate-v1;
                            }
                        }
                    }

                    &:hover {
                        background-color: $slate !important;
                    }
                }
            }
        }

        .yotpo-messages {
            margin: 45px 0 35px;

            .yotpo-thank-you {
                border-top: 1px solid $slate;
                border-right: none;
                border-bottom: none;
                border-left: none;
                padding-bottom: 0;

                .yotpo-icon-heart,
                .yotpo-icon-btn {
                    display: none;
                }

                span.yotpo-share-message {
                    font-family: $brand-font;
                    font-size: 20px;
                    text-transform: uppercase;
                    margin: 0 0 8px;
                    display: block;
                    color: $slate;
                }

                .yotpo-thankyou-header {
                    padding-top: 20px;

                    span {
                        font-size: 46px;
                        font-family: $brand-font;
                        font-weight: normal;
                        @include media-breakpoint-down(lg) {
                            font-size: 32px;
                        }
                        @include media-breakpoint-down(sm) {
                            font-size: 28px;
                            line-height: 32px;
                        }
                    }
                }

                .yotpo-thankyou-content span {
                    color: $slate;
                    font-family: $body-font;
                    font-size: 16px;
                    display: block;
                    margin-bottom: 8px;

                    br {
                        display: none;
                    }
                }

                .yotpo-thankyou-footer .yotpo-icon-btn {
                    display: inline-block;
                    background: none;
                    padding: 0;
                    margin: 0;

                    .social-link {
                        padding: 0 3px;
                        display: block;
                    }

                    &[data-network="linkedin"] {
                        display: none;
                    }

                    .yotpo-icon {
                        font-size: 40px;
                    }

                    .yotpo-icon-facebook,
                    .yotpo-icon-twitter {
                        background-color: $slate;
                        border-radius: 50%;
                        width: 33px !important;
                        height: 33px;
                        font-size: 23px;

                        &::before {
                            text-align: center;
                            display: block;
                            line-height: 33px;
                        }
                    }

                    .yotpo-icon-button-text {
                        display: none;
                    }
                }
            }
        }

        .yotpo-nav-content {
            .search-in-progress {
                padding-top: 30px;
            }

            .yotpo-first-review {
                .yotpo-first-review-stars {
                    display: none;
                }

                .yotpo-first-review-content {
                    .write-first-review-button,
                    .write-first-question-button {
                        background: none;
                        color: $slate;
                        font-family: $brand-font;
                        font-size: 46px;

                        @include media-breakpoint-down(md) {
                            font-size: 28px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 18px;
                        }

                        &:hover {
                            background-color: $white !important;
                            color: $slate !important;
                            text-decoration: underline;
                        }
                    }
                }
            }

            .yotpo-questions {
                .yotpo-question {
                    .yotpo-helpful {
                        margin-right: 22px;
                    }

                    .yotpo-icon-profile {
                        display: none;
                    }

                    .content-question {
                        font-weight: normal;

                        &::first-letter {
                            font-weight: bold;
                        }

                        &.yotpo-font-bold {
                            color: $slate;
                            font-family: $body-font;
                        }
                    }

                    .yotpo-comments-box {
                        margin-top: 15px;
                        border-left: none;

                        .yotpo-comment-box {
                            padding: 0 0 0 20px;
                            margin-left: 0;
                            border-left: 2px solid $blue-v1;

                            .content-question {
                                color: $slate;
                            }
                        }
                    }

                    .yotpo-footer {
                        margin-left: 22px;
                    }

                    .yotpo-header {
                        .label-with-tooltip.pull-left {
                            padding-top: 0;
                        }

                        .yotpo-user-name {
                            font-size: 16px;
                        }

                        .yotpo-header-element .y-label {
                            vertical-align: bottom;
                            line-height: 15px;
                        }
                    }

                    .yotpo-main {
                        margin-left: 0;

                        .content-question {
                            float: left;
                        }
                    }
                }
            }

            .yotpo-reviews,
            .yotpo-questions {
                .yotpo-empty-search-results {
                    padding-top: 30px;

                    div {
                        width: 90%;
                    }

                    span {
                        top: auto;
                    }
                }
            }

            .yotpo-reviews-filters.yotpo-active {
                border-bottom: none;
                padding-bottom: 0;

                .free-text-search {
                    width: auto;

                    .yotpo-icon-clear-text-search {
                        right: 25px;
                    }
                }

                .filters-container-title {
                    font-family: $brand-font;
                    font-weight: normal;
                    text-transform: uppercase;
                    padding-top: 20px;
                    font-size: 20px;
                    color: $slate;
                }

                .filters-container {
                    .free-text-search-input {
                        border-radius: 3px;
                        -webkit-appearance: none;
                        box-sizing: border-box;
                    }

                    .filters-dropdown {
                        .yotpo-dropdown-button label {
                            width: 80%;
                            font-family: $body-font;
                        }

                        &[data-default-button-display-value="Gender"],
                        &[data-default-button-display-value="Age"] {
                            display: none;
                        }

                        &[data-analytic-property] {
                            &:not([data-analytic-property="All"]) {
                                .yotpo-dropdown-button {
                                    background-color: $slate-v2 !important;

                                    label {
                                        background: none !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .yotpo-reviews-header {
                border-bottom: 1px solid $gray;
                height: 65px;

                .reviews-amount {
                    display: none;
                }
            }

            .yotpo-review,
            .yotpo-question {
                border-bottom: 1px solid $gray;
                padding: 25px 0 20px;
                color: $slate;

                .yotpo-icon-profile {
                    display: none;
                }

                .yotpo-header {
                    float: left;
                    width: 265px;

                    .label-with-tooltip {
                        padding-top: 0.15em;
                    }

                    .yotpo-user-name {
                        font-size: 16px;
                        text-transform: unset;
                        font-family: $univers-bold;

                        @include media-breakpoint-down(lg) {
                            .yotpo-user-title {
                                line-height: 23px;
                            }
                        }
                    }

                    .yotpo-header-actions {
                        float: none;
                        clear: both;
                        width: 100%;
                        margin: 15px 0;

                        .yotpo-review-date {
                            font-size: 14px;
                            color: $slate;
                        }
                    }

                    .question-date {
                        font-size: 14px;
                        color: $gray;
                    }

                    .yotpo-user-related-fields {
                        .yotpo-user-field[data-type="SingleChoice"] {
                            display: none;
                        }

                        .yotpo-user-field-description,
                        .yotpo-user-field-answer {
                            color: $slate;
                            font-size: 14px;
                            font-style: unset !important;
                        }

                        .yotpo-user-field-description {
                            font-family: $body-font-bold;
                            font-weight: normal;
                        }
                    }
                }

                .yotpo-main {
                    float: right;
                    width: calc(100% - 300px);
                    margin: 0;
                    position: relative;

                    .content-review {
                        color: $slate;
                    }

                    .yotpo-multiple-rating-fields {
                        display: none;
                    }

                    .yotpo-read-more {
                        font-family: $body-font-bold;
                    }

                    > div {
                        float: left;
                        color: $slate;
                        width: calc(100% - 335px);

                        &.yotpo-font-bold {
                            font-weight: normal;
                            font-family: $body-font-bold;
                        }

                        &.yotpo-review-images-wrapper {
                            position: absolute;
                            top: 0;
                            right: 0;
                            width: 330px;
                            margin-top: 0;

                            img {
                                width: 100px;
                                height: auto;
                                float: right;
                                margin: 0 0 10px 10px;
                            }
                        }

                        @include media-breakpoint-down(lg) {
                            width: 100%;

                            &.yotpo-review-images-wrapper {
                                position: relative;
                                width: 100%;
                                margin-top: 23px;

                                img {
                                    float: none;
                                    margin: 10px 10px 0 0;
                                }
                            }
                        }

                        @include media-breakpoint-down(md) {
                            float: none;
                        }
                    }
                }

                .yotpo-footer {
                    margin-left: 0;
                    padding-top: 15px;
                    clear: both;
                    width: 100%;
                    color: $gray;

                    &.visible {
                        margin-top: 0;
                    }

                    .share-options-wrapper .share-options {
                        .yotpo-action {
                            line-height: 32px;
                        }

                        .yotpo-icon-dot {
                            vertical-align: bottom;
                            display: inline-block;

                            &::before {
                                font-size: 32px;
                                margin-left: -10px;
                                letter-spacing: -8px;
                            }
                        }

                        .yotpo-icon-seperator {
                            display: none;
                        }
                    }

                    .yotpo-action {
                        line-height: 32px;
                    }

                    .yotpo-helpful label {
                        font-family: $body-font;
                        letter-spacing: 0.5px;
                    }
                }
            }

            .yotpo-pager {
                padding-top: 10px;

                .yotpo-page-element.goTo {
                    font-family: $brand-font;
                    color: $gray-ada !important;
                    font-size: 20px;

                    &.yotpo-active {
                        color: $black !important;
                    }
                }

                .yotpo-icon-left-arrow {
                    margin-right: 20px;
                }

                .yotpo-icon-right-arrow {
                    margin-left: 10px;
                }

                .yotpo-icon-left-arrow::before,
                .yotpo-icon-right-arrow::before {
                    color: $yellow;
                }
            }
        }

        @include media-breakpoint-down(md) {
            .yotpo-display-wrapper {
                display: block;
                text-align: center;

                .yotpo-regular-box.bottom-line-items-container {
                    display: inline-block;
                    text-align: left;

                    .bottom-line-items {
                        text-align: center;

                        .rating-stars-container {
                            display: inline-block;
                            float: none;
                            margin: 0 auto 10px;
                        }

                        .reviews-qa-labels-container {
                            margin-left: 0;
                            width: 100%;
                            position: relative;
                        }
                    }

                    .aggregated-product-related-fields {
                        .product-related-fields-column {
                            width: 100%;
                        }

                        .product-related-fields-item {
                            display: flex;
                            justify-content: center;

                            .product-related-fields-item-value {
                                width: auto;
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            .aggregated-product-related-fields .product-related-fields-mobile-layout {
                display: block;
            }

            .aggregated-product-related-fields .product-related-fields-desktop-layout {
                display: none;
            }

            .yotpo-display-wrapper .yotpo-regular-box .aggregated-product-related-fields .product-related-fields-item-title {
                width: 90px;
            }

            .yotpo-nav-content {
                .filters-container .free-text-search-input {
                    width: 95%;
                }

                .yotpo-review,
                .yotpo-question {
                    .yotpo-header,
                    .yotpo-main {
                        width: 100%;
                        float: none;
                    }

                    .yotpo-main {
                        margin: 10px 0;

                        .yotpo-mobile-slides-container {
                            width: 100% !important;
                            margin-top: 20px;

                            img {
                                margin: 0 5px 5px 0 !important;
                            }
                        }
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            .yotpo-questions .yotpo-question .yotpo-main,
            .yotpo-nav-content .yotpo-review .yotpo-main {
                width: 100%;
                float: none;
                margin-top: 0.5rem;
                clear: both;
            }
        }
    }

    &.reviews-page {
        max-width: 1256px;
        margin: 40px auto 0;

        .recommendations .tiles-container.slides {
            margin: 0 auto 50px;
        }

        .yotpo-modal-dialog > .yotpo-label {
            display: none;
        }

        #yotpo-testimonials-custom-tab .yotpo-review .yotpo-main {
            .content-review,
            .content-title {
                color: $slate;
            }

            .product-link-wrapper {
                float: none;
                clear: both;
                display: block;

                .product-link {
                    font-family: $brand-font;
                    text-transform: uppercase;
                    color: $slate;
                    font-size: 16px;
                    float: left;
                    display: inline-block;
                    text-decoration: underline;
                }
            }
        }
    }
}

.recommendations .product-tile .yotpo {
    margin: auto;

    .standalone-bottomline {
        .yotpo-bottomline.pull-left {
            float: none;
            margin: 3px auto 5px;
            cursor: default !important;
        }

        .text-m {
            display: none;
        }
    }
}

.product-tile .yotpo-bottomline {
    padding: 1px 0 4px;
    margin-left: -2px;
    cursor: default !important;

    .text-m {
        margin-left: 10px;
        color: $slate;
        font-family: $brand-font-condensed;
        font-size: 12px;
        text-transform: uppercase;

        &:hover {
            color: $slate !important;
        }
    }
}

.product-grid.slides {
    .product-tile .yotpo.bottomLine {
        margin-left: 0;

        .yotpo-stars {
            display: flex;
            justify-content: center;
        }
    }
}

.home-secondary .yotpo .standalone-bottomline .star-clickable {
    cursor: default;
}

.set-items .yotpo-bottomline.pull-left {
    cursor: default !important;

    &:hover {
        cursor: default !important;
    }

    .text-m {
        cursor: default !important;

        &:hover {
            color: $slate !important;
            cursor: default !important;
        }
    }
}

.pt_reviews {
    .primary-content {
        position: relative;

        .reviews-featured {
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 0;

            li {
                list-style: none;
                max-width: 450px;
                margin: 80px 12px 60px;
                text-align: center;
                display: block;

                a {
                    font-family: $brand-font;
                    text-transform: uppercase;
                    text-decoration: underline;
                }

                img {
                    width: 100%;
                    height: auto;
                }

                .shoppers-say-title {
                    display: none;
                }

                .sentence-collapsed {
                    .display-name {
                        font-family: $body-font;
                        font-size: 14px;
                        margin-bottom: 0;
                    }

                    .sentence {
                        font-size: 14px;
                        font-family: $body-font;
                        font-style: normal !important;
                    }

                    .yotpo-icon-down-arrow {
                        color: $blue !important;
                        margin: 18px 0 25px;
                        display: block;
                    }
                }

                .sentence-expanded {
                    .sentence-container {
                        background: none;
                        padding-bottom: 0;
                        margin-bottom: 0;

                        .more-reviews {
                            display: none;
                        }

                        .single-sentence-text-content {
                            .display-name {
                                font-family: $body-font;
                                font-size: 14px;
                                color: $gray;
                                margin-bottom: 0;
                            }

                            .full-review {
                                font-family: $body-font;
                            }

                            .review-title {
                                font-family: $brand-font;
                                text-transform: uppercase;
                                font-size: 20px;
                                font-weight: normal;
                            }
                        }
                    }

                    .yotpo-icon-up-arrow {
                        color: $blue !important;
                        margin: 18px 0 25px;
                        display: block;
                    }
                }

                .yotpo-regular-box {
                    padding-bottom: 0;
                }
            }

            @include media-breakpoint-down(sm) {
                display: block;
                width: 90%;
                margin: auto;

                li {
                    width: calc(100% - 24px);
                }
            }
        }

        .reviews-page {
            h2 {
                @include h3();

                border-bottom: 1px solid $slate;
                margin: 30px 10px 0;
                padding-bottom: 20px;
                font-size: 42px;
                letter-spacing: 0;

                &:last-of-type {
                    border-bottom: none;
                }
            }

            .yotpo-modal-bottom-line {
                border: none;
                border-top: none !important;
                position: absolute;
                top: 170px;
                left: 0;
                right: 0;
                margin: auto;

                .yotpo-bottomline {
                    float: none;
                    text-align: center;

                    .text-m {
                        color: $white !important;
                        display: block;
                        margin-top: 8px;
                        font-family: $brand-font;
                        font-size: 16px;
                        text-transform: uppercase;
                    }
                }

                @include media-breakpoint-down(md) {
                    top: 120px;
                }
            }

            .yotpo-popup-box-large {
                padding: 0;

                .yotpo-messages {
                    display: none;
                }
            }

            .yotpo-nav-primary {
                border-bottom: 1px solid $slate;

                .yotpo-nav-tab {
                    text-transform: uppercase;
                }

                .yotpo-nav-tab:not(.yotpo-active):hover .yotpo-nav-wrapper span {
                    color: $blue !important;
                }
            }

            @include media-breakpoint-down(sm) {
                width: 90%;

                .yotpo-review,
                .yotpo-question {
                    position: relative;

                    .yotpo-header {
                        position: unset;

                        .yotpo-header-actions {
                            margin: 0 !important;

                            .yotpo-review-date {
                                position: absolute;
                                bottom: 20px;
                                left: 0;
                            }
                        }
                    }

                    .footer-actions {
                        float: none;
                        width: 100%;
                        margin-bottom: 10px;
                    }

                    .yotpo-helpful {
                        .label-helpful {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .reviews-page-header {
        background-color: $slate;
        background-size: cover;
        background-position: center;
        padding: 50px 0 100px;

        h2 {
            @include h2();

            color: $white;
            text-align: center;
            font-size: 66px;
        }
    }
}

.pt_seo-category .product-tile .yotpo {
    text-align: center;
    margin: auto;

    .standalone-bottomline {
        .yotpo-bottomline.pull-left {
            float: none;
            margin: 3px auto 5px;

            .yotpo-stars {
                display: flex;
                justify-content: center;
            }
        }

        .text-m {
            display: none;
        }
    }
}

.pt_product-compare #compare-table.tiles-container {
    .yotpo .pull-left {
        float: none;
        text-align: center;
    }
}

#write-review-tabpanel-main-widget > div.form-element.submit-button,
#write-question-tabpanel > div.form-element.submit-button {
    float: left;
}

.yotpo-reviews-carousel {
    .yotpo-carousel {
        .product-name.carousel-review-product-text {
            font-size: 12px !important;
        }
    }
}
