@import "../utilities/swatch";
@import "productCommon";
@import "quickView";

.primary-images {
    .carousel {
        img {
            width: 100%;
            height: 100%;
        }
    }
}

/////////////////////////////
// REGULAR PRODUCT TILES
.product-tile {
    border: 0;
    margin-bottom: 0;
    padding: 25px 25px 5px;
    background-color: $white;
    position: relative;
    transition: 0.5s;

    &.product-tile-set {
        padding-bottom: 50px;

        .tile-body {
            .pdp-link {
                padding-bottom: 0;
            }
        }
    }

    .image-container {
        position: relative;
        padding-top: 100%;

        a {
            display: block;

            .tile-image,
            .tile-image-hover {
                position: absolute;
                top: -30px;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            &.has-hover-image {
                .tile-image,
                .tile-image-hover {
                    transition: 0.75s;
                }

                .tile-image {
                    opacity: 1;
                }

                .tile-image-hover {
                    opacity: 0;
                }
            }

            &:hover,
            &:focus {
                text-decoration: none;
                outline: none;
            }

            /* stylelint-disable selector-pseudo-class-no-unknown */
            &:focus-visible {
                outline: none;
                border: none;
                box-shadow: 0 0 0 2px $slate;
                padding: 0 2px 2px;
                border-radius: $border-radius;
            }
        }
    }

    .product-badges-and-name {
        position: relative;
        text-align: left;
        top: 5px;
        margin-top: 3px;

        .tile-badges {
            min-height: 25px;
        }

        .pdp-link {
            padding: 5px 0;
            text-decoration: none;
            text-transform: uppercase;
            line-height: 1;
            @include h4();
        }
    }

    .pdp-link {
        padding: 5px 0;
        text-decoration: none;
        text-transform: uppercase;
        line-height: 1;
        @include h4();
    }

    .promotion-callout {
        @include text-sm();

        color: $red;
        margin-top: 2px;
    }

    .tile-body {
        position: relative;
        margin-top: 5px;

        .tile-body-footer {
            .ratings {
                font-size: 0.9em;
            }
        }

        .price {
            @include text-lg();

            line-height: 22px;
            margin-bottom: 0;

            .tiered {
                font-size: 0.875em;

                .value {
                    font-weight: bold;
                }
            }
        }

        .coming-soon-tile {
            text-align: center;
        }

        .product-tile__buyallorone {
            font-size: $lg-font-size;
            margin-top: 60px;
        }
    }

    .color-swatches {
        display: flex;
        flex-direction: column;
        width: 25px;
        transition: 0.5s;
        position: absolute;
        top: 25px;
        right: 25px;

        .product-tile-color-label {
            cursor: pointer;
            font-size: 1em;
        }

        ul.swatches {
            list-style-type: none;
            padding-left: 0;
        }

        .swatches {
            a {
                text-decoration: none;
                display: inline-block;
                border-radius: 50%;
                padding: 3px;

                &:focus-visible,
                &.active {
                    border: 1px solid $slate;
                    outline: none;
                    padding: 2px;
                }

                &.selected {
                    border: 2px solid $slate;
                    padding: 2px;
                }

                .swatch-circle {
                    border-radius: 50%;
                    width: 20px;
                    height: 20px;
                }
            }

            span {
                display: flex;
            }
        }
    }

    .tile-tool-count {
        transition: 0.5s;
        position: absolute;
        top: 25px;
        left: 25px;
    }

    .custom-checkbox.tile-checkbox {
        &:focus-within {
            .tile-checkbox-label::before {
                border-color: $gray-ada !important;
                box-shadow: 0 0 0 0.17rem $gray-ada !important;
            }
        }
    }

    .product-tile-buttons {
        a.quickview {
            opacity: 0;
            transition: 0.75s;

            &:hover,
            &:active {
                opacity: 1;
            }
        }
    }

    // for accessibility...
    // hover tile, or focus anywhere in it; get QV button
    &:hover a.quickview,
    &:focus-visible a.quickview,
    &:focus-within a.quickview,
    &.tile-qv-hover a.quickview {
        opacity: 1;
    }

    .yotpo-display-wrapper {
        .standalone-bottomline {
            margin: 0 auto;

            .yotpo-stars {
                margin-right: 5px;
            }

            .yotpo-bottomline .text-m {
                margin-left: 0;
                color: #393b42 !important;
                font-family: $trade-gothic-bold-condensed;
                font-size: $sm-font-size;
                text-transform: uppercase;
                white-space: nowrap;
                cursor: default !important;

                &:hover {
                    // right now these go nowhere; (see 'cursor' just above)
                    // making them appear that they do is not accessible
                    // color: $gray-ada !important;
                }
            }
        }
    }

    &.product-tile-set {
        .tile-body {
            .product-tile__buyallorone {
                font-size: $lg-font-size;
                margin-top: 5px;
            }

            .tile-body-footer {
                margin-top: 0;
            }
        }
    }

    &.product-tile-apparel {
        .image-container {
            position: relative;
            padding-top: 85%;
        }

        .product-badges-and-name {
            top: 25px;
            margin-top: 5px;
        }

        .tile-body-footer {
            margin-top: 25px;
        }
    }

    .spectrum-image-container {
        min-height: 320px;

        .spectrum-tile-image {
            width: 100%;
            height: 320px;
            object-fit: contain;
        }
    }

    @include media-breakpoint-up(lg) {
        &:hover {
            .image-container {
                a.has-hover-image {
                    display: block;
                }
            }
        }

        .image-container {
            a.has-hover-image:hover {
                .tile-image {
                    opacity: 0;
                }

                .tile-image-hover {
                    opacity: 1;
                }
            }
        }
    }
}

/////////////////////////////
// BONUS PRODUCT MODAL
#chooseBonusProductModal {
    .modal-footer {
        .container {
            margin-left: 0;
            width: 100%;
            margin-right: 0;
        }
    }

    .select-cbp-container {
        margin-top: auto;
        margin-bottom: auto;
    }

    .product-name-wrapper {
        width: 100%;
    }

    .bonus-quantity,
    .bonus-option {
        margin-top: 0.938em;
    }

    .bonus-quantity-select {
        min-width: 5em;
    }

    .select-bonus-product {
        margin-top: 1em;
    }

    .selected-pid {
        border: 1px solid $grey3;

        .bonus-product-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .bonus-product-price {
        text-align: center;
        margin-top: 1em;
    }
}

/////////////////////////////
// SIMPLE PRODUCT TILES
.simple-product-tile {
    padding: 0 20px;

    a {
        outline: none;
        text-decoration: none;
    }

    .product-tile-image {
        position: relative;
        font-size: 1em;

        figure {
            margin: 0;
        }

        .product-tile-component-figure {
            width: 100%;
        }

        .product-tile-component-image {
            width: 100%;
            opacity: 1;
            transition: 0.75s;
        }

        .product-tile-component-image-secondary {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            opacity: 0;
            transition: 0.75s;
        }

        &.has-second-image:hover {
            .product-tile-component-image {
                opacity: 0;
            }

            .product-tile-component-image-secondary {
                opacity: 1;
            }
        }
    }

    .product-tile-body {
        .product-name-link {
            font-size: $heading-4-font-size;
            font-family: $brand-font;
            color: $slate;
            text-transform: uppercase;
            line-height: 1;
            padding-top: 10px;
            padding-bottom: 5px;
        }

        .tile-body-footer .ratings {
            margin: 0 auto;

            &.col-xs-12,
            &.col-sm-6 {
                flex: 0 0 100%;
                max-width: 100%;
            }

            .yotpo-display-wrapper {
                .yotpo-stars {
                    cursor: default !important;
                }
            }
        }

        .price {
            margin-top: 4px;
            margin-bottom: 5px;

            .sales {
                font-weight: $base-font-weight;
            }

            .strike-through {
                text-decoration: line-through;
            }
        }

        .product-description {
            color: $slate;
        }
    }

    .yotpo-display-wrapper {
        .standalone-bottomline {
            margin: 0 auto;

            .yotpo-stars {
                margin-right: 5px;
            }

            .text-m {
                margin-left: 0;
                color: #393b42;
                font-family: $trade-gothic-bold-condensed;
                font-size: $sm-font-size;
                text-transform: uppercase;
                white-space: nowrap;

                &:hover {
                    // right now these go nowhere; (see cursor just above)
                    // making them appear that they do is not accessible
                    // color: $gray-ada !important;
                }
            }
        }
    }

    .product-tile-buttons {
        a.quickview {
            opacity: 1;
        }

        .btn {
            &:focus-visible {
                border: 2px solid $black;
                border-radius: $border-radius;
            }
        }
    }
}

.product-tile-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 10px;

    > * {
        margin-right: 10px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    a {
        text-decoration: none;
    }

    &.center-buttons {
        justify-content: center;

        > * {
            margin: 5px 10px;
        }
    }
}

.cat-garage .product-tile .tile-badges {
    padding-top: 25px;
}
