// Using wrapper to avoid !important
#wrapper.layout-grid {
    #main {
        max-width: 1800px;
    }

    .blog-landing {
        position: relative;
    }

    .blog-back {
        position: absolute;
        @include h5();

        color: $white;
        text-transform: uppercase;
        top: 5px;
        padding: 0;

        &:hover {
            color: $blue;
        }

        &:focus {
            color: $blue;
        }
    }

    .blog-category-menu-item-link {
        @include h5();

        text-transform: uppercase;
        color: $grey6;

        &:hover {
            text-decoration: none;
        }

        &:focus {
            text-decoration: none;
        }
    }

    .blog-category-menu-item:hover,
    .blog-category-menu-item:focus {
        border-bottom-color: $yellow;
    }

    .blog-category-menu-item.selected {
        border-bottom-color: $yellow;
    }

    .blog-menu-toggle {
        @include h4();

        text-transform: uppercase;
        margin-bottom: 0;

        &::after {
            background-image: url("../images/ui/icon_plus.svg");
            display: inline-block;
            width: 20px;
            height: 35px;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: contain;
            border-style: none;
            transform: none;
        }

        &.active::after {
            background-image: url("../images/ui/icon_minus.svg");
            display: inline-block;
            width: 20px;
            height: 35px;
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: contain;
            border-style: none;
            transform: rotate(-180deg);
        }
    }

    .blog-content {
        border-top: none;

        .view-more {
            padding: 40px;
        }

        .view-more-button {
            @include button();
            @include button-primary();
            @include button-large();

            line-height: 46px;
            margin: 0 auto;
            width: 100%;
            height: 78px;
        }
    }

    //blog grid overrides
    .blog-post {
        .blog-post-title {
            @include h3();

            text-transform: uppercase;
            margin: 0 auto 30px;
        }

        .blog-post-inner {
            padding: 0;
            margin: 40px 40px 0;
            position: relative;
        }

        .article-image {
            height: 100%;
            padding-top: 60%;
        }

        .read-more {
            text-transform: uppercase;
        }

        .blog-post-content {
            color: $slate;
            left: 0;
            position: relative;
            top: 0;
            height: calc(100% - 80px);
        }

        &.post-1 {
            flex: 0 1 100%;
            text-shadow: 0 0 20px $black;

            .blog-post-inner {
                height: 485px;
                margin: 0;
            }

            .blog-post-title {
                font-size: 30px;
            }

            .blog-post-content {
                width: auto;
                padding: 40px;
                margin: 40px;
                position: absolute;
                color: $white;

                @include media-breakpoint-up(md) {
                    width: 36.5%;
                    border: 1px solid $white;
                    background: rgba(0, 0, 0, 0.4);
                }
            }

            .article-image {
                padding-top: 0;
            }

            .blog-category-name {
                @include media-breakpoint-up(md) {
                    display: none;
                }
            }

            .description {
                display: flex;
                flex-direction: column;
            }

            .text {
                margin: 0 auto 40px;
                display: block;
            }

            .read-more {
                @include button();
                @include button-secondary();
                @include button-large();

                color: $white;
                width: fit-content;
            }
        }

        &.post-2,
        &.post-3 {
            flex: 0 1 100%;
            text-shadow: 0 0 20px $black;

            @include media-breakpoint-up(md) {
                flex: 0 1 50%;
            }

            .blog-post-inner {
                height: 396px;
            }

            .blog-post-content {
                padding: 18% 40px 40px;
                margin: 0;
                position: absolute;
                color: $white;
            }

            .article-image {
                padding-top: 0;
            }
        }
    }
}

// post styling
#maincontent {
    .blog-post-banner {
        padding-top: 31.25vw;
        position: relative;
        overflow: hidden;
        max-width: 1800px;
        margin: 0 auto;

        img {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            transform-style: preserve-3d;
            position: absolute;
            width: 100%;
        }
    }

    .blog-breadcrumbs {
        font-family: $brand-font;
        text-transform: uppercase;
        border-top: none;

        .blog-breadcrumbs-element {
            font-size: $base-font-size;
            color: $gray-ada;
        }

        .blog-breacrumbs-link {
            color: $slate;
        }
    }

    .full-blog-post {
        max-width: 1800px;
        padding: 20px;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            padding: 32px 17.858% 90px;
        }

        .blog-hr {
            border: 0;
            height: 3px;
            margin: 20px auto;
            width: 30.864%;
            background: $blue;
        }

        .post-body {
            font-size: $base-font-size;

            > *:not(.wp-block-image) {
                padding: 0;
                max-width: 100%;
            }
        }

        .post-details {
            padding: 0;
            margin: 0 auto;
        }

        .post-category {
            text-transform: uppercase;
        }

        .post-title {
            @include h2();

            text-transform: uppercase;
        }

        img {
            height: auto;
            margin: 10px 0;
            max-width: 100%;
            vertical-align: top;

            &.aligncenter {
                display: block;
                margin: 40px auto;
            }
        }
    }
}
