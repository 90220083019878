@import "collapsibleItem";
@import "toastMessage";

@mixin footer-accordion-button() {
    background-color: $slate;
    color: $white;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border: none;
    border-top: 1px solid $white;

    &:focus,
    &:hover {
        outline: none;
    }

    /* stylelint-disable selector-pseudo-class-no-unknown */
    &:focus-visible {
        outline: none;
        box-shadow: inset 0 0 0 2px $white;
    }
}

@mixin footer-column-title {
    font-family: $brand-font-condensed;
    font-size: $heading-5-font-size;
    text-transform: uppercase;
    font-stretch: condensed;
    color: $white;
    line-height: 30px;
    margin-bottom: 0;
}

@mixin footer-accordion-drawer {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $white;
    font-size: $lg-font-size;
    font-stretch: condensed;
    margin-block-end: 0;

    a {
        color: $slate;
        line-height: normal;
    }

    li {
        padding: 0 40px 18px;

        &:first-child {
            padding-top: 18px;
        }
    }
}

footer {
    background-color: $slate;
    color: $white;

    .container-fluid {
        padding: 0;
    }

    ul {
        list-style: none;
        padding-left: 0;

        li {
            list-style-type: none;
        }
    }

    .visually-hidden {
        display: none;
    }

    hr {
        margin: 40px 0 0;
    }

    .footer-top {
        justify-content: center;
        align-items: center;
        gap: 60px;
        padding: 40px 0;
        background-color: $white;
        color: $slate;
        border-top: 1px solid $slate-v1;
        margin: 0 auto;

        .feature-box {
            display: flex;
            align-items: center;

            p.msg {
                margin-bottom: 0;
                margin-left: 20px;
                text-transform: uppercase;
                @include h5();

                a {
                    &:focus-visible {
                        outline: 2px solid $slate;
                        border-radius: $border-radius;
                        outline-offset: 3px;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            gap: 12px;
            margin: 0;
            padding: 20px;

            .feature-box p.msg {
                margin-left: 12px;
                @include h6();
            }
        }

        // a Tekla-specific width
        $sm-max-t: 740px;
        @media (max-width: $sm-max-t) {
            flex-direction: column;
            justify-content: start;
            align-items: flex-start;
        }
    }

    .footer-container {
        max-width: 1600px;
        padding-top: 40px;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            padding-top: 20px;
        }

        &__checkout {
            padding: 27px 15px 7px;
            justify-content: flex-start;
            font-size: 16px;

            a {
                color: $white;
                font-family: $body-font-bold;
            }
        }

        .newslettersocial {
            @include media-breakpoint-down(md) {
                padding: 0;
                margin-bottom: 30px;
            }

            .newsletter-signup {
                color: $white;
                margin-bottom: 40px;

                @include media-breakpoint-down(md) {
                    padding: 0 20px;
                    margin-bottom: 20px;
                }

                .email-description {
                    font-family: $brand-font;
                    font-size: 20px;
                    line-height: 1.2;
                    letter-spacing: normal;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                }

                .newsletter-signup-label {
                    margin-bottom: 12px;
                }

                .email-input {
                    max-width: 500px;

                    @include media-breakpoint-down(lg) {
                        max-width: 100%;
                    }
                }

                #hpEmailSignUp-footer {
                    margin-right: 1px;
                }

                .confirmation-message {
                    @include media-breakpoint-down(xs) {
                        padding-top: 63px;
                        padding-bottom: 67px;
                    }

                    .confirmation-title {
                        font-family: $brand-font;
                        font-size: 25px;
                        line-height: 1;
                        letter-spacing: normal;
                        text-transform: uppercase;
                        margin-bottom: 5px;
                    }

                    .confirmation-copy {
                        font-family: $body-font;
                        font-size: 14px;
                        line-height: 1.42;
                        letter-spacing: normal;
                    }
                }

                .dpc {
                    flex-wrap: nowrap;

                    .input-group-append {
                        width: calc(100% - 16px);
                    }
                }

                .dpcCheckbox {
                    height: 16px;
                    width: 16px;
                    border-radius: 2px;
                    margin-right: 7px;
                    background-color: $white;
                    margin-top: 3px;
                }

                .privacy-copy {
                    font-family: $body-font;
                    font-size: 12px;
                    line-height: 1.42;
                    letter-spacing: normal;
                    margin-top: 12px;

                    a {
                        font-family: $body-font-bold;
                        color: white;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                .dpc-copy {
                    font-family: $body-font;
                    font-size: 14px;
                    line-height: 1.42;
                    letter-spacing: normal;

                    p {
                        margin-bottom: 0;

                        a {
                            text-decoration: none;
                            color: white;
                            font-family: $body-font-bold;
                        }
                    }
                }
            }
        }

        .footer-column {
            .footer-column__title,
            .legal-links-title {
                @include footer-column-title();
            }

            button {
                @include footer-accordion-button();
            }

            .collapsing {
                @include transition(height 0.7s ease-in-out);
            }

            // this is only the 'follow us' + social icons section
            &.no-mobile-collapse {
                display: flex;
                flex-direction: column;

                .footer-column__title {
                    margin-bottom: 7px;
                }

                .footer-column__body {
                    ul.social-icons {
                        list-style-type: none;
                        display: flex;
                        flex-direction: row;

                        li.social-icon {
                            width: 35px;
                            height: 35px;
                            margin-right: 14px;

                            a {
                                display: flex;
                                width: 100%;
                                height: 100%;

                                &:focus {
                                    outline: none;
                                }

                                &:focus-visible {
                                    outline: none;

                                    .icon-social {
                                        outline: 3px solid $white;
                                        outline-offset: 2px;
                                    }
                                }

                                .icon-social {
                                    width: 100%;
                                    height: 100%;

                                    &::after {
                                        width: 90%;
                                        height: 90%;
                                    }
                                }
                            }
                        }
                    }
                }

                @include media-breakpoint-down(md) {
                    height: 85px;
                    border-top: 1px solid $white;
                    padding-top: 20px;
                    padding-right: 10px;
                    padding-left: 20px;
                    margin-bottom: 10px;

                    .footer-column__title {
                        margin-right: 15px;
                        margin-bottom: 5px;
                    }

                    .break {
                        flex-basis: 100%;
                        height: 0;
                    }

                    .footer-column__body {
                        display: flex;
                        flex-direction: row;
                        justify-content: left;
                        width: 100%;
                        flex-grow: 1;

                        ul.social-icons {
                            li.social-icon {
                                width: 45px;
                                height: 45px;
                                margin-right: 12px;

                                a {
                                    width: 100%;
                                    height: 100%;

                                    .icon {
                                        width: 100%;
                                        padding-top: 100%;
                                        height: auto;
                                    }

                                    .icon-social {
                                        &::after {
                                            width: 100%;
                                            height: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // a LevelAccess-specific width
                $xs-ada: 370px;
                @media (max-width: $xs-ada) {
                    ul.social-icons {
                        li.social-icon {
                            width: 35px !important;
                            height: 35px !important;
                        }
                    }
                }
            }
        }

        .footer-item__right-columns {
            padding: 0;
            padding-left: 20px;

            h2 {
                margin-bottom: 0;
            }

            @include media-breakpoint-down(md) {
                padding: 0;
            }

            .row {
                margin: 0;
                justify-content: flex-end;

                @include media-breakpoint-down(md) {
                    margin: 0;

                    .footer-column {
                        padding: 0;
                    }
                }

                .footer-item {
                    max-width: 180px;
                    padding: 0;

                    @include media-breakpoint-down(md) {
                        max-width: 100%;
                    }
                }

                a {
                    color: white;
                    line-height: 2;

                    &:focus {
                        outline: none;
                        text-decoration: underline;
                    }

                    &:focus-visible {
                        outline: 2px solid $white;
                        outline-offset: 5px;
                        border-radius: $border-radius;
                    }
                }

                @include media-breakpoint-up(lg) {
                    .collapse {
                        // display: block !important;
                    }
                }

                @include media-breakpoint-down(md) {
                    .footer-column__body {
                        ul {
                            @include footer-accordion-drawer();
                        }
                    }

                    .footer-column__button {
                        height: 60px;
                        border-top: 1px solid $white;
                        border-width: 1px 0;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 20px;

                        .footer-column__title {
                            font-family: $brand-font-condensed;
                            font-stretch: condensed;
                        }

                        .icon {
                            width: 20px;
                            height: 20px;
                            filter: invert(1);
                            transition: transform 0.35s ease-in-out;
                        }
                    }
                }
            }
        }

        .footer-item.collapsible-xs button {
            padding: 0;
        }
    }

    .footer-bottom {
        max-width: 1600px;
        min-height: 50px;
        font-size: $sm-font-size;
        margin: 0 auto;

        @include media-breakpoint-down(md) {
            flex-direction: column-reverse;

            .col {
                padding: 0;
            }
        }

        .copyright-container {
            height: 70px;

            @include media-breakpoint-down(md) {
                border-top: 1px solid $white;
            }

            .copyright {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-size: $sm-font-size;
                padding: 0 15px;

                @include media-breakpoint-down(md) {
                    padding-left: 20px;
                }
            }

            .level-access-logo {
                display: block;
                background-image: url("../../images/level_access_icon.svg");
                background-repeat: no-repeat;
                width: 62px;
                height: 31px;
            }
        }

        .legal-links {
            min-width: 450px;
            height: 100%;
            font-size: $sm-font-size;
            float: right;

            @include media-breakpoint-down(md) {
                width: 100%;
                min-width: auto;

                h2 {
                    margin-bottom: 0;
                }

                .footer-column__button {
                    @include footer-accordion-button();

                    .legal-links-title {
                        @include footer-column-title();
                    }

                    .icon {
                        width: 20px;
                        height: 20px;
                        filter: invert(1);
                        transition: transform 0.35s ease-in-out;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                .collapse {
                    // display: block !important;
                    height: 100%;
                }
            }

            a {
                color: $white;

                &:focus {
                    outline: none;
                    text-decoration: underline;
                }

                &:focus-visible {
                    outline: 2px solid $white;
                    outline-offset: 5px;
                    border-radius: $border-radius;
                }
            }

            ul {
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 100%;
                height: 100%;
                margin-bottom: 0;

                li {
                    margin-right: 15px;

                    &:last-child {
                        margin: 0;
                    }
                }

                a {
                    color: $white;

                    &:focus {
                        outline: none;
                        text-decoration: underline;
                    }

                    &:focus-visible {
                        outline: 2px solid $white;
                        outline-offset: 5px;
                        border-radius: $border-radius;
                    }
                }

                @include media-breakpoint-down(md) {
                    @include footer-accordion-drawer();
                }
            }
        }
    }

    .email-error,
    .dpc-error {
        display: none;
        color: $red;
        background-color: #ffeaec;
        border: solid 1px $red;
        padding: 2px 9px;
        margin-top: 9px;
        max-width: 500px;
    }

    .dpc-error {
        margin-bottom: 14px;
        max-width: none;
    }

    .email-input {
        max-width: 500px;

        @include media-breakpoint-down(lg) {
            max-width: 100%;
        }
    }

    .confirmation-message {
        @include media-breakpoint-down(xs) {
            padding-top: 63px;
            padding-bottom: 67px;
        }

        .confirmation-title {
            font-family: $brand-font;
            font-size: $heading-4-font-size;
            line-height: 1;
            letter-spacing: normal;
            text-transform: uppercase;
            margin-bottom: 5px;
        }

        .confirmation-copy {
            font-family: $body-font;
            font-size: $base-font-size;
            line-height: 1.42;
            letter-spacing: normal;
        }
    }

    .dpc-input {
        margin-top: 15px;
    }

    .store {

        @include media-breakpoint-down(xs) {
            border-bottom: 1px solid $dark-gray;

            .content {
                display: none;
            }

            h2 {
                @include clearfix;

                &::after {
                    font-family: "FontAwesome";
                    float: right;
                    content: "\f041";
                }
            }
        }
    }

    .content {
        font-size: 0.875em;

        li {
            height: 1.875rem;
        }
    }
}

#consent-tracking {
    .button-wrapper {
        button {
            margin: 0.5em;
        }
    }
}

.email-signup-message {
    @include toast-message();
}

.email-signup-alert {
    @include toast-alert();
}
