@import "../utilities/swatch";
@import "../variables";

.site-search {
    position: relative;
    height: 2.5em; /* 40/16 */

    @include media-breakpoint-up(sm) {
        margin-right: 25px;
    }

    @include media-breakpoint-up(xl) {
        max-width: 200px;
    }

    .search-button,
    .reset-button {
        position: absolute;
        border: none;
        padding: 0;
        background-color: transparent;
    }

    .reset-button {
        background-color: $white;
    }

    .search-button {
        left: 5px;
        top: 4px;
        width: 27px;
        height: 27px;

        .icon {
            width: 25px;
            height: 25px;
        }

        /* stylelint-disable selector-pseudo-class-no-unknown */
        &:focus,
        &:focus-visible {
            box-shadow: 0 0 0 0.17rem $slate;
            border-radius: $border-radius;
            outline: none;
        }
    }

    .reset-button {
        right: 10px;
        top: 10px;

        .icon {
            width: 16px;
            height: 16px;
        }
    }

    input {
        padding: 0 35px;
        padding-bottom: 2px;
        width: 200px;
        height: 36px;
        border-radius: 5px;
        font-size: 16px;

        &::placeholder {
            color: $gray-ada;
        }

        @include media-breakpoint-down(lg) {
            width: 180px;
        }

        &::-ms-clear {
            display: none;
        }

        @include media-breakpoint-down(md) {
            width: 220px;
        }

        // a LevelAccess-specific width
        $xs-ada: 370px;
        @media (max-width: $xs-ada) {
            width: 170px !important;
        }

        &:focus,
        &:focus-visible {
            box-shadow: 0 0 0 0.17rem $slate;
            border-radius: $border-radius;
            outline: none;
        }
    }
}

@include media-breakpoint-down(xs) {
    .header-search {
        .site-search {
            display: none;
        }
    }
}

.suggestions-wrapper {
    position: relative;
}

.suggestions {
    display: block;
    position: absolute;
    border: 1px solid $grey3;
    background-color: $white;
    top: 21px;
    right: -25px;
    width: 300px;
    z-index: 3;

    @include media-breakpoint-down(md) {
        border-top: 0;
        display: flex;
        position: fixed;
        width: 100%;
        overflow-y: scroll !important;
    }

    .swatch-circle {
        @include swatch(2.5em, $white);
    }

    ul.sub-container {
        list-style-type: none;
        padding-left: 0;

        li {
            list-style-type: none;
        }
    }

    .header {
        font-family: $body-font;
        font-size: 12px;
        color: $slate;
        padding: 20px 20px 0;
        height: auto;
        border: none;

        .col {
            padding: 0;
        }

        h2,
        h2.do-you-mean,
        h2.suggested-products,
        h2.suggested-categories,
        h2.suggested-content {
            font-family: $body-font;
            font-size: 12px;
            color: $gray-ada;
        }

        &:not(:first-child) {
            border-top: 1px solid $grey3;
        }
    }

    .items {
        height: auto;
        list-style-type: none;
        padding-left: 0;

        h3 {
            font-family: $body-font;
            font-size: $base-font-size;
        }

        li {
            list-style-type: none;
        }
    }

    .item {
        padding: 10px 30px 0 20px;

        &:last-child {
            padding-bottom: 20px;
        }

        a {
            width: 100%;
            text-decoration: none;

            &:focus {
                border: none;
                outline: none;
            }

            &:focus-visible {
                outline: 2px solid $slate;
                border-radius: $border-radius;
                outline-offset: 2px;
            }
        }

        .row {
            height: auto;
            margin: 0;
        }

        .col {
            padding: 0;
            display: flex;
            align-items: center;
        }

        img {
            width: 50px;
            height: 50px;
            margin-right: 10px;
        }

        @include media-breakpoint-down(xs) {
            padding-bottom: 0.938em;
        }
    }

    .category-parent {
        color: $grey4;
        font-size: 0.875em;
    }

    .selected {
        background-color: $grey2;
    }

    .container {
        list-style-type: none;
        margin: 0;
        max-width: none;
    }
}

.more-below {
    -moz-border-radius: 1.25em;
    background: $grey3;
    border: 0.063em solid rgba(0, 0, 0, 0.1);
    border-radius: 1.25em;
    bottom: 1.875em;
    box-shadow: 0 1px 7px rgba(0, 0, 0, 0.3);
    display: none;
    height: 2.5em;
    position: fixed;
    right: 1.875em;
    width: 2.5em;

    i.fa-long-arrow-down {
        border-radius: 50%;
        color: $white;
        display: table-caption;
        height: 0.75em;
        font-size: 1.5rem;
        left: 0.57em;
        line-height: 0.8em;
        position: absolute;
        top: 0.4em;
        width: 0.8em;
    }
}

body {
    // a LevelAccess-specific width
    $xs-ada: 325px;
    @media (max-width: $xs-ada) {
        overflow-x: hidden;
    }
}
