.tooltip {
    top: -5px !important;
    pointer-events: none;

    .arrow::before {
        background: white;
    }

    .arrow {
        width: 25px;
        height: 25px;
        overflow: hidden;
        position: absolute;
        left: 50% !important;
        margin-left: -15px;
        bottom: -20px;
    }

    .arrow::before {
        content: "";
        position: absolute;
        top: -20px;
        width: 25px;
        height: 25px;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &.show {
        opacity: 1;
    }
}

.tooltip .arrow::before,
.tooltip-inner {
    box-shadow: 0 0 5px #aaa;
}

.tooltip-inner {
    background-color: $white;
    color: $slate;
    max-width: 195px;
    padding: 15px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: condensed;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
}

[data-toggle="tooltip"] {
    cursor: pointer;
}

.tooltip__termsandconditions {
    font-size: 10px;
    text-align: left;
    font-weight: normal;
    max-width: 272px;

    ul {
        padding-left: 15px;
        margin: 0;

        div {
            margin-left: -5px;
        }
    }
}
