@import "variables";
@import "bootstrap/scss/mixins/breakpoints";

#spectrumGroupDetailsModal {
    .modal-header {
        border-bottom: 1px solid $grey3;

        .modal-title {
            text-transform: uppercase;
        }
    }

    .modal-body {
        padding: 0 30px 30px;
    }

    .spectrum-group-details {
        width: 100%;
        min-width: 0;

        @include media-breakpoint-up(lg) {
            max-width: 1020px;
        }

        .product-info-inner {
            padding: 10px 0;
            flex-direction: column;

            @include media-breakpoint-up(lg) {
                flex-direction: row;
            }

            &:not(:last-child) {
                border-bottom: 1px solid $grey3;
            }
        }

        .item-image {
            width: 100%;
            height: auto;
            display: flex;
            margin: 0 0 20px;
            justify-content: center;
            flex-grow: 0;
            flex-shrink: 0;

            @include media-breakpoint-up(sm) {
                display: block;
                width: 33%;
                max-width: 235px;
                margin: 0 20px 0 0;
            }

            img.product-image {
                width: 100%;
                display: block;
                height: auto;
            }
        }

        .item-attributes {
            width: 100%;

            @include media-breakpoint-up(lg) {
                width: 66%;
            }
        }

        .line-item-name {
            font-size: $lg-font-size;
        }

        .line-item-attributes {
            margin-bottom: 10px;

            &__spectrum-header {
                @include h6();

                text-transform: uppercase;
                margin-bottom: 20px;
            }

            &__spectrum-group {
                @include h6();

                text-transform: uppercase;
                margin-top: 20px;
            }
        }

        .line-item-attribute {
            margin-bottom: 0;
        }

        .line-item-attribute-name {
            font-family: $body-font-bold;
        }
    }
}
