html,
body {
    touch-action: pan-y;
}

.page {
    background-color: $slate-v2;
}

.page-blank {
    background-color: $white;
}

.modal-background,
.header-nav__modal-background {
    background-color: $black;
    display: none;
    height: 100vh;
    overflow: hidden;
    touch-action: none;
    opacity: 0.5;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1050;
    position: fixed;
}

input[placeholder] {
    text-overflow: ellipsis;
}

header ~ a:not(.btn-primary, .btn-outline-primary) {
    color: $blue;
}

.hide-order-discount {
    display: none;
}

.hide-shipping-discount {
    display: none;
}

.order-discount {
    color: $red;
}

.shipping-discount {
    color: $red;
}

.error-messaging {
    position: fixed;
    bottom: 15px;
    width: 60%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 9999;

    @include media-breakpoint-down(lg) {
        width: 55%;
    }

    @include media-breakpoint-down(md) {
        width: 90%;
        bottom: 90px;
    }
}

.error-hero {
    background-image: url('../images/storelocator.jpg');
    margin-bottom: 0.625em;
}

.error-message {
    margin-top: 3.125rem;
    margin-bottom: 3.125rem;
}

.error.continue-shopping {
    margin-bottom: 6.25em;
}

.error-unassigned-category {
    color: $red;
}

.skip {
    position: absolute;
    left: 0;
    top: -4.2em;
    overflow: hidden;
    padding: 1em 1.5em;
    background: $white;
    transition: all 0.2s ease-in-out;

    &:active,
    &:focus,
    &:hover {
        top: 1px;
        width: auto;
        height: auto;
        z-index: 10000000;
    }

    &.accessibility-message {
        left: 150px;
        top: -30em;

        @include media-breakpoint-up(sm) {
            margin-right: 10%;
        }

        @include media-breakpoint-down(sm) {
            margin-right: 5px;
            padding: 1em;
        }

        &:active,
        &:focus,
        &:hover {
            left: 150px;
            top: 1px;
        }
    }
}

.card-header-custom {
    font-size: 1.5rem;
    margin-bottom: 0;
}

sup {
    font-size: 60%;
}
