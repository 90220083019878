@import "bootstrap/scss/mixins/breakpoints";

.product-slider,
.media-card-slider {
    position: relative;

    .slick-track {
        display: flex;
    }

    .slick-slide {
        height: inherit;
    }

    .slick-list {
        padding: 10px;
    }

    .slider-prev,
    .slider-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: none;
        background-color: transparent;
        cursor: pointer;
        width: 15px;
        height: 30px;
        padding: 0;

        /* stylelint-disable selector-pseudo-class-no-unknown */
        &:focus-visible {
            outline: 2px solid $slate;
            border-radius: $border-radius;
            outline-offset: 2px;
        }
    }

    .slider-prev {
        left: -50px;
    }

    .slider-next {
        right: -50px;
    }

    .invert-arrows {
        filter: invert(1);
    }
}

.product-tile-slider {
    @include media-breakpoint-down(lg) {
        .col-12 {
            margin-bottom: 20px;

            &.ratings {
                margin-bottom: 0;
            }
        }
    }
    @include media-breakpoint-up(lg) {
        .product-tile-column {
            transition: 0.5s;
            padding-left: 10px;
            padding-right: 10px;
            margin-bottom: 20px;
            position: relative;

            .product {
                transition: 0.5s;
                box-shadow: 0 0 0 transparent;
                height: 100%;

                .product-tile {
                    height: 100%;
                }
            }

            &::before {
                content: '';
                display: inline-block;
                transition: 0.5s;
                width: calc(100% - 20px);
                height: 100%;
                border: 0 solid $white;
                position: absolute;
                top: 0;
                left: 10px;
                opacity: 0;
            }
        }
    }
}

