/**
* Buttons
* Example use:
* <button class="button button--primary">text</button>
*/

@mixin button() {
    font-family: $trade-gothic-bold-condensed;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.25s;
    display: inline-block;
}

.btn {
    @include button();
}

@mixin button-primary {
    color: $slate;
    background-color: $yellow;
    border: none;

    // Hack to prevent the hover effects
    // on chromium based mobile browsers
    /* stylelint-disable scss/media-feature-value-dollar-variable */
    @media (hover: hover) {
        &:hover {
            color: $white;
            background-color: $slate;
            text-decoration: none;
        }

        &.disabled {
            color: $slate;
            background-color: $slate-v1;

            &:hover {
                color: $slate;
                background-color: $slate-v1;
            }
        }

        // pseudo-class
        &:disabled {
            color: $slate;
            background-color: $slate-v1;

            &:hover {
                color: $slate;
                background-color: $slate-v1;
            }
        }
    }

    &:active {
        color: $white;
        background-color: $slate;
        text-decoration: none;
    }

    &.disabled {
        color: $slate;
        background-color: $slate-v1;

        &:active {
            color: $slate;
            background-color: $slate-v1;
        }
    }

    // pseudo-class
    &:disabled {
        color: $slate;
        background-color: $slate-v1;

        &:active {
            color: $slate;
            background-color: $slate-v1;
        }
    }
}

@mixin button-secondary {
    color: $slate;
    background-color: transparent;
    border: 2px solid $yellow;

    &:hover {
        border-color: $blue;
        text-decoration: none;
    }

    &.disabled {
        color: $slate;
        border-color: $gray;

        &:hover {
            color: $slate;
            border-color: $gray;
        }
    }

    // pseudo-class
    &:disabled {
        color: $slate;
        border-color: $gray;

        &:hover {
            color: $slate;
            border-color: $gray;
        }
    }
}

@mixin button-regular {
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.3px;
    padding: 15px 30px;
}

@mixin button-large {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.4px;
    padding: 16px 30px;
}

@mixin button-small {
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0.24px;
    padding: 6px 23px;
}

@mixin btn-primary {
    @include button-regular();
    @include button-primary();
}

.btn-primary {
    @include btn-primary();

    &.btn-lg {
        @include button-large();
    }

    &.btn-sm {
        @include button-small();
    }

    &.outline,
    &.subscribe-email {
        &:hover,
        &:active {
            box-shadow: inset 0 0 0 2px $yellow;
            margin-left: 1px;
        }

        /* stylelint-disable selector-pseudo-class-no-unknown */
        &:focus-visible {
            outline: none;
            background-color: $slate;
            color: $white;
            box-shadow: inset 0 0 0 2px $yellow;
            margin-left: 1px;
        }
    }
}

@mixin btn-secondary {
    @include button-regular();
    @include button-secondary();
}

.btn-secondary {
    @include btn-secondary();

    padding: 13px 30px;

    &.btn-lg {
        @include button-large();
    }

    &.btn-sm {
        @include button-small();

        padding: 4px 23px;
    }
}

@mixin btn-secondary-slate {
    @include button-regular();
    @include button-secondary-slate();
}

.btn-secondary-slate {
    @include btn-secondary();

    border: 2px solid $slate;
    color: $slate;
    padding: 13px 30px;

    &.btn-lg {
        @include button-large();
    }

    &.btn-sm {
        @include button-small();

        padding: 4px 23px;
    }
}

@mixin btn-link {
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.53px;
    padding: 0;
    text-decoration: underline;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: inherit;
}

.btn-link {
    @include btn-link();
}

@mixin btn-link-inline {
    padding: 0;
    text-decoration: underline;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: inherit;
    text-transform: none;
    font-family: $body-font-bold;
}

.btn-link-inline {
    @include btn-link-inline();
}

.btn-video {
    .icon.icon-play {
        height: 25px;
        width: 25px;
        margin: -4px 7px -8px -5px;
    }

    &.btn-primary:hover:not(.disabled) .icon.icon-play {
        filter: invert(1);
    }

    &.btn-lg {
        @include button-large();

        .icon.icon-play {
            height: 30px;
            width: 30px;
        }
    }

    &.btn-sm {
        @include button-small();

        .icon.icon-play {
            height: 15px;
            width: 15px;
            margin: -5px 7px -3px -5px;
        }
    }
}
