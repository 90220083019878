@import "../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "../utilities/shapes";
@import "../utilities/responsiveUtils";
@import "../utilities/swatch";
@import "./attributes";
@import "../components/headers";

.product-detail {
    // override full bleed to meet comps
    max-width: 1280px !important;

    @include media-breakpoint-up(md) {
        .product-detail__right {
            max-width: 380px;
        }
    }

    .product-attribute-option .swatch-circle {
        // $white will be replaced by color image background in ISML
        @include swatch(32px, $white);

        @include media-breakpoint-down(sm) {
            @include swatch(39px, $white);
        }
    }

    .add-to-cart,
    .notify-me {
        width: 100%;
    }

    .product__promotion-wrapper {
        background-color: $blue-v1;
        padding: 15px;
        text-align: left;
        margin: 15px 0;
        width: 100%;

        .product__promotion-specialoffer {
            @include h6();

            text-transform: uppercase;
        }

        .product__promotion-callout {
            @include h3();

            text-transform: uppercase;
            margin: 0;
        }

        .product__promotion-details {
            font-size: 12px;
            font-family: $body-font-bold;
        }
    }

    .quantity {
        margin-bottom: 15px;

        .quantity-msg-container {
            display: flex;
            align-items: center;

            button.icon {
                height: 10px;
                width: 10px;
                border: none;
                background-color: transparent;
            }

            .quantity-msg {
                width: 70%;
                color: $red;
                font-family: $body-font-bold;
                margin-left: 10px;
            }
        }
    }

    .customize,
    .engrave {
        margin-bottom: 15px;

        a {
            text-decoration: none;
        }

        .btn {
            width: 100%;
            padding: 11px 30px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.btn-secondary:hover {
                border-color: $slate;
                text-decoration: none;
            }
        }

        .icon {
            width: 18px;
            height: 18px;
            margin-right: 5px;
        }
    }

    .engraving-dialog {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;

        .modal-dialog {
            @include media-breakpoint-up(md) {
                min-width: 800px;
            }

            .modal-content {
                padding-top: 50px;

                .modal-body {
                    padding: 25px 20px;
                    @include media-breakpoint-up(md) {
                        padding: 90px;
                        padding-top: 30px;
                    }

                    .modal-title {
                        @include h3();

                        text-transform: uppercase;
                    }

                    .required-asterisk-legend {
                        margin-bottom: 18px;
                    }
                }
            }
        }

        .engraving-dialog__preview {
            background-color: $slate-v2;
            margin: 30px 0;

            .engraving-dialog__preview-container {
                display: block;
                position: relative;
                height: 155px;
                width: 400px;
                margin: 25px auto;
                overflow: hidden;
                flex: none;
                padding: 0;
            }
        }

        .engraving-dialog__preview-image {
            width: 400px;
            position: absolute;
            top: 0;
            bottom: 0;
        }

        .engraving-dialog__preview-text {
            border: 1px dashed $red;
            text-align: center;
            position: absolute;

            .engraving-dialog__preview-text-sizer {
                white-space: nowrap;
            }
        }

        .engraving-dialog__terms-conditions {
            padding-top: 10px;

            ul {
                padding: 0 20px;
            }
        }

        .engraving-dialog__add-update-engraving,
        .engraving-dialog__cancel-remove-engraving {
            width: 140px;
        }

        .engraving-dialog__add-update-engraving {
            margin-right: 20px;
            padding: 15px 20px;
        }

        .engraving-dialog__tosagree {
            margin: 30px 0;
        }

        .is-invalid {
            height: 1.5em;
            opacity: 1;
            visibility: visible;
        }
    }

    .prices-add-to-cart-actions {
        padding: 0;

        .add-to-cart-item-summary {
            display: none;
        }

        @include media-breakpoint-down(md) {
            &.stick-to-top {
                width: 100vw;
                position: fixed;
                z-index: 1040;
                left: 15px;
                right: 0;
                animation: fadeIn 0.5s;
                transition: 0.5s;

                @keyframes fadeIn {
                    from {
                        opacity: 0;
                    }

                    to {
                        opacity: 1;
                    }
                }

                .cart-and-ipay {
                    background-color: $white;
                    border-bottom: 1px solid $gray;
                    padding-bottom: 0;
                    width: 100%;
                }

                .item-summary-button-container {
                    display: flex;
                    justify-content: space-between;
                }

                .add-to-cart-item-summary {
                    display: flex;
                    align-items: center;
                    text-transform: uppercase;
                    @include h6();

                    .price {
                        font-size: 15px;
                    }
                }

                .add-to-cart-item-name-price {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    text-align: left;
                    margin: 0 10px;
                }

                .add-to-cart-item-image img {
                    width: 60px;
                    height: 60px;
                }

                .add-to-cart {
                    width: auto;
                }
            }
        }
    }

    .notify-me-container {
        width: 100%;
        margin-top: 20px;
        padding-left: 5px;
        padding-right: 5px;

        .notify-me-form {
            border: 1px solid $red;
            padding: 15px;
        }

        .notify-me-availability {
            color: $red;
            margin-bottom: 15px;
        }

        .notify-me-message {
            text-transform: uppercase;
            margin-bottom: 5px;
            @include h6();
        }

        .notify-me-confirmation,
        .confirmation-message {
            width: 100%;
            border: 1px solid $slate;
            padding: 25px 15px;
        }
    }

    .tool-count {
        position: absolute;
        width: 60px;
        height: 60px;
        color: $slate;
        background-color: $white;
        border-radius: 50%;
        border: 2px solid $slate;
        text-align: center;
        text-transform: uppercase;
        font-family: $brand-font-condensed;
        z-index: 1;
        left: 10px;
        top: 10px;

        @include media-breakpoint-up(sm) {
            left: 110px;
        }
        @include media-breakpoint-up(md) {
            left: 130px;
            width: 80px;
            height: 80px;
        }
        @include media-breakpoint-up(lg) {
            left: 160px;
            top: 20px;
            width: 92px;
            height: 92px;
        }

        &.knifeless {
            @include media-breakpoint-up(sm) {
                left: 60px;
            }
            @include media-breakpoint-up(md) {
                left: 50px;
            }
            @include media-breakpoint-up(lg) {
                left: 160px;
            }
        }
    }

    .tool-count-number {
        border-bottom: 2px solid $slate;
        font-size: 25px;
        line-height: 1.3em;
        width: 60%;
        margin: 0 auto;

        @include media-breakpoint-up(md) {
            font-size: 35px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 41px;
        }
    }

    .tool-count-label {
        font-size: 12px;
        line-height: 1.8em;

        @include media-breakpoint-up(md) {
            font-size: 13px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 15px;
        }
    }
}

.row {
    justify-content: center;
}

.page[data-action="Product-Show"] {
    background-color: $white;
}

.product-detail .product-attribute-option .swatch-circle {
    // $white will be replaced by color image background in ISML
    @include swatch(32px, $white);

    @include media-breakpoint-down(sm) {
        @include swatch(39px, $white);
    }
}

.product-detail [disabled] .swatch-circle,
.product-detail .swatch-circle.unselectable {
    cursor: not-allowed;

    &.color-value.selected::after {
        background-color: $gray-700;
    }
}

label.color ~ a:hover {
    // Removes weird dash after the swatch circle on hover.
    text-decoration: none;
}

.product-details-container {
    margin-top: 50px;
    padding-left: 20px;
    padding-right: 20px;

    @include media-breakpoint-down(md) {
        margin-top: 40px;
    }

    .product-detail-bold {
        font-family: $body-font-bold;
        text-transform: capitalize;
    }

    .product-detail-section {
        padding: 10px;
        padding-bottom: 20px;

        @include media-breakpoint-down(sm) {
            border-bottom: solid 1px $slate;
            padding: 10px 0;
        }
    }

    .details-container {
        @include media-breakpoint-down(sm) {
            border-top: solid 1px $slate;
        }
    }

    .product-detail-title {
        @include h3();

        width: 100%;
        height: 54px;
        padding-bottom: 19px;
        align-items: center;
        text-align: left;
        text-transform: uppercase;
        border-bottom: solid 1px $slate;
        border-left: none;
        border-top: none;
        border-right: none;
        background-color: $white;
        color: $slate;

        @include media-breakpoint-down(sm) {
            height: auto;
            padding: 19px 0;
            border-bottom: none;
        }

        .icon {
            width: 23px;
            transition: transform 0.35s ease-in-out;
        }

        &:focus,
        &:hover {
            outline: none;
        }

        /* stylelint-disable selector-pseudo-class-no-unknown */
        &:focus-visible {
            outline: 2px solid $slate;
            outline-offset: 2px;
        }
    }

    .warranty-logo {
        margin-right: 15px;
        object-fit: contain;
        width: 30px;
    }

    .details-body {
        &__includedbits {
            margin-top: 15px;

            .label {
                font-family: $body-font-bold;
                text-transform: uppercase;
            }

            ul {
                list-style: none;
                padding: 0;
            }
        }
    }

    .product-detail-body {
        &.collapse-large {
            @include media-breakpoint-up(lg) {
                display: block !important;
                padding-top: 19px;
            }

            @include media-breakpoint-down(lg) {
                padding-bottom: 19px;
            }
        }

        &.collapse-medium {
            @include media-breakpoint-up(md) {
                display: block !important;
                padding-top: 19px;
            }

            @include media-breakpoint-down(md) {
                padding-bottom: 19px;
            }
        }

        a {
            text-decoration: underline;
        }

        .btn.btn-secondary.learn-more {
            &:hover {
                border-color: $slate;
                text-decoration: none;
            }
        }
    }

    .works-body {
        margin-left: 20px;
        font-family: $body-font-bold;

        ul {
            column-count: 2;
            list-style: none;
            padding: 0;
            margin: 0;

            @include media-breakpoint-down(xs) {
                column-count: 1;
            }
        }

        .works-with-product-link {
            text-decoration: none;
        }
    }

    .apparel-body {
        margin-left: 20px;
        font-family: $body-font;

        ul {
            column-count: 2;
            padding: 0;
            margin: 0;
            list-style: initial;

            @include media-breakpoint-down(xs) {
                column-count: 1;
            }
        }

        li {
            @include media-breakpoint-up(lg) {
                padding-right: 5px;
            }
        }
    }

    .tools-body {
        margin-left: 20px;

        ul {
            column-count: 2;
            padding: 0;
            margin: 0;
            list-style: initial;

            @include media-breakpoint-down(xs) {
                column-count: 1;
            }
        }
    }

    .features-body {
        ul {
            p {
                margin-bottom: 5px;
            }
        }
    }

    .warranty-body .learn-more {
        margin-top: 19px;
    }
}

.save-share {
    margin-top: 20px;
}

.save-modal-trigger,
.share-modal-trigger {
    display: flex;
    text-transform: uppercase;
    cursor: pointer;
    line-height: 22px;
    background-color: $white;
    border: 0;
    outline-offset: 3px;
    align-items: center;
    overflow: auto;

    i {
        height: 22px;

        &.icon-bookmark-hover,
        &.icon-bookmark-filled {
            width: 22px;
            margin-right: 5px;
        }
    }
}

#saveDialog {
    $xs-ada: 370px;

    .modal-dialog {
        @include media-breakpoint-up(md) {
            width: 716px;
            max-width: 716px;
        }
    }

    .modal-header {
        padding: 40px 40px 0;

        @media (max-width: $xs-ada) {
            padding: 40px 30px 0;
        }

        @include media-breakpoint-up(md) {
            padding: 90px 90px 0;
        }

        h2.modal-title {
            width: 100%;
            padding-bottom: 20px;
            margin-bottom: 15px;
            border-bottom: solid 2px $slate;
            text-transform: uppercase;
            @include h4();
        }
    }

    .modal-body {
        padding: 5px 40px 40px;

        @media (max-width: $xs-ada) {
            padding: 5px 30px 40px;
        }

        @include media-breakpoint-up(md) {
            padding: 5px 90px 90px;
        }

        a {
            text-decoration: underline;
        }

        .wishlist-product-card {
            .product-img {
                max-width: 140px;

                @media (max-width: $xs-ada) {
                    max-width: 120px;
                }
            }

            .product-name h3 {
                @include h5();

                text-transform: uppercase;
            }

            .product-details {
                margin-bottom: 0;

                .detail-line {
                    display: flex;
                    flex-direction: row;
                    min-height: 21px;
                    line-height: 1.15;

                    @media (max-width: $xs-ada) {
                        font-size: $sm-font-size;
                    }
                }

                dt {
                    margin-right: 2px;
                }
            }
        }

        .wishlist-buttons {
            margin-top: 25px;

            .wishlist-button {
                width: 100%;

                @include media-breakpoint-down(sm) {
                    &.close-wishlist-modal {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}

#socialDialog {
    .modal-dialog {
        @include media-breakpoint-up(md) {
            width: 728px;
            max-width: 728px;
        }
    }

    .modal-header {
        padding: 40px 40px 0;
        @include media-breakpoint-up(md) {
            padding: 90px 90px 0;
        }
    }

    .modal-body {
        padding: 32px 40px 40px;
        @include media-breakpoint-up(md) {
            padding: 32px 90px 90px;
        }
    }

    h2.modal-title {
        text-transform: uppercase;
        @include h3();
    }
}

.social-container {
    .social-icons {
        text-decoration: none;
        list-style-type: none;
        padding: 0;
        margin: 0;
        justify-content: flex-start;
        flex-wrap: wrap;

        .icon-social {
            width: 45px;
            height: 45px;
        }

        .copy-link-item {
            flex-basis: 100%;
        }

        li {
            margin: 0 12.5px 25px;

            &:first-of-type {
                margin-left: 0;
            }

            &:last-of-type {
                margin: 0;
            }

            a {
                display: flex;
            }
        }
        @include media-breakpoint-up(md) {
            justify-content: space-between;
            flex-wrap: nowrap;

            .copy-link-item {
                flex-basis: unset;
            }

            li {
                margin: 0;
            }
        }
    }
}

label.availability {
    padding-right: 0.3125em;
}

h1.product-name {
    text-transform: uppercase;
    font-size: 30px;

    @include media-breakpoint-up(sm) {
        font-size: 40px;
    }
}

.product-number {
    color: $product-number-grey;
    font-size: 0.875em;
    padding-bottom: 0.938em;
    padding-top: 0.938em;
}

.promotions {
    margin: 0;
}

.promotions-col {
    font-weight: normal;
    text-align: center;
    height: 85%;

    div.collapsible-xl button.callout {
        font-size: 1.25em;
        padding-top: 1.25rem;
        color: $danger;

        @include media-breakpoint-up(sm) {
            font-size: 1.375em;
        }

        &::after {
            color: $black;
        }
    }
}

.product-breadcrumb {
    font-family: $brand-font;
    text-transform: uppercase;
    max-width: 1900px;
    padding-left: 25px;
    margin: 0 auto;

    @include media-breakpoint-down(sm) {
        padding-left: 15px;
    }

    .breadcrumb {
        border-bottom: none;
        margin-left: -1rem;
        margin-bottom: 0;
        color: $black;

        a {
            outline-offset: 3px;
        }

        .breadcrumb-item:last-child {
            a {
                color: $gray-ada;
            }
        }
    }
}

.product-details-top {
    .product-details-description {
        margin-top: 20px;

        .product-short {
            margin-bottom: 0;
        }
    }

    .prices {
        margin-top: 7px;
        padding: 0;
    }

    .price {
        font-family: $brand-font-condensed;
        font-size: 20px;
    }

    .ratings {
        @include media-breakpoint-up(sm) {
            margin-top: 0.625em;
        }

        @include media-breakpoint-only(xs) {
            background-color: $white;
            margin-top: -1.875em; /* 30/16 */
            margin-right: -0.9375em; /* 15/16 */
            padding: 0.1875em; /* 3/16 */
        }
    }
}

.kosm-pdp {
    iframe {
        margin-top: 10px;
        margin-bottom: 3px;

        @include media-breakpoint-down(sm) {
            margin: 0 auto;
        }
    }
}

.postal-code.btn.btn-link.btn-sm {
    outline-offset: 2px;
}

.attributes {
    margin-bottom: 20px;

    .attribute {
        margin-top: 13px;

        ul.attribute-options {
            list-style-type: none;
        }

        .attribute-options {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            overflow-x: auto;
            padding: 5px 20px;
            margin: 0 -15px;
            line-height: 30px;
            width: 100vw;
            -ms-overflow-style: none;
            /* stylelint-disable-next-line property-no-unknown */
            scrollbar-width: none;

            &::-webkit-scrollbar {
                display: none;
            }

            @include media-breakpoint-up(sm) {
                overflow: visible;
                flex-wrap: wrap;
                align-content: center;
                width: auto;
                padding: 5px 5px 5px 3px;
                margin: unset;
            }

            li {
                list-style-type: none;
                display: flex;
            }

            .product-attribute-option {
                border: none;
                padding: 0;
                background: none;
                height: 100%;

                &:hover,
                &:focus {
                    outline: none;

                    .swatch-circle.selectable {
                        box-shadow: 0 0 0 1.5px $slate !important;
                        -webkit-box-shadow: 0 0 0 2px $slate !important;
                    }
                }

                .color-value.swatch-circle,
                .sheath-value.swatch-circle,
                .packaging-value.swatch-circle {
                    margin-right: 8px;
                    top: 5px;
                }

                .color-value.swatch-circle.selected,
                .sheath-value.swatch-circle.selected,
                .packaging-value.swatch-circle.selected {
                    box-shadow: 0 0 0 2.5px $slate !important;
                    -webkit-box-shadow: 0 0 0 3px $slate !important;
                    top: 0;
                }

                .sheath-value.swatch-circle.unselectable:not(.selected) {
                    border: none;
                    margin-right: 0;
                }

                &:focus-visible {
                    .swatch-circle.swatch-value.selected.unselectable {
                        box-shadow: 0 0 0 1.5px $slate !important;
                        -webkit-box-shadow: 0 0 0 2px $slate !important;
                    }
                }

                &[data-url=null] {
                    margin-right: -4px;
                }
            }
        }
    }
}

.product-options {
    margin-top: 1.25em;
    margin-bottom: 0;
    @include media-breakpoint-up(sm) {
        margin-bottom: 1.25em;
    }
}

.size-chart {
    .size-chart-collapsible {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s;
        transition-timing-function: cubic-bezier(0, 1, 0, 1);

        &.active {
            max-height: 100%;
            transition-timing-function: ease-in-out;
        }
    }
}

.fitsize-attribute-wrapper {
    max-width: 350px;

    ul.fitsize-attribute-swatches {
        list-style-type: none;
        display: inline-block;
        padding-left: 0;

        &.sheath-size-swatches {
            padding: 0 2px 1px 1px;
        }

        li {
            display: inline;
        }
    }

    .fitsize-attribute-swatches {
        // float: left;
    }

    .fitsize-attribute-swatches-error {
        display: none;
    }

    &.error {
        .fitsize-attribute-swatches-error {
            display: block;
            color: $red;
        }

        .fitsize {
            color: $red;
        }

        .fitsize-attribute-swatches {
            border: 1px solid $red;
        }
    }
}

.fitsize-attribute {
    width: 45px;
    height: 45px;
    background-color: $white;
    border: 1px solid $gray-ada;
    padding: 0;
    margin: 2.5px 0 2.5px 1px;

    &:hover,
    &:focus-visible {
        border: 2px solid $slate;
    }

    &.selected {
        border: 3px solid $slate;
    }

    &.unselectable {
        position: relative;
        color: $gray-ada;
        cursor: initial;

        &::before {
            position: absolute;
            content: "";
            left: -8px;
            top: 21px;
            border-top: 1px solid $gray-ada;
            border-color: inherit;
            width: 140%;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.model-attribute-wrapper {
    .fitsize-attribute {
        width: 150px;
    }

    .fitsize-attribute.model-swatch {
        height: 45px;
        background-color: $white;
        border: 1px solid $gray-ada;
        padding: 0;
        margin: 2.5px 0 2.5px 1px;

        &:hover,
        &:focus-visible {
            border: 2px solid $slate;
        }

        &.selected {
            border: 3px solid $slate;
            border-radius: 0;
        }

        &.unselectable {
            position: relative;
            color: $gray-ada;
            cursor: initial;

            &::before {
                position: absolute;
                content: "";
                left: -4px;
                top: 21px;
                border-top: 1px solid $gray-ada;
                border-color: inherit;
                width: 105%;
                -webkit-transform: rotate(16deg);
                -moz-transform: rotate(16deg);
                -ms-transform: rotate(16deg);
                -o-transform: rotate(16deg);
                transform: rotate(16deg);
            }
        }
    }
}

.sheath .icon-info,
.packaging .icon-info {
    width: 15px;
    height: 15px;
    margin: 0 0 -2px 5px;
}

.attribute .fitsize,
.attribute .model,
.attribute .carrySystemAttatchment {
    display: inline-block;
}

.attribute .apparel-size-chart {
    text-decoration: underline;
    margin-left: 10px;
    cursor: pointer;
}

header ~ #maincontent .container a.sizelink:not(.btn-primary):not(.btn-outline-primary) {
    text-decoration: underline;
    color: $slate;
    margin-left: 10px;
}

#size-chart-modal {
    .modal-dialog {
        @include media-breakpoint-down(sm) {
            max-width: 90%;
            margin: 20px auto;
        }
    }

    .modal-body {
        max-height: 100%;
        margin-top: 45px;
    }
}

.size-chart {
    .size-chart-collapsible {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s;
        transition-timing-function: cubic-bezier(0, 1, 0, 1);

        &.active {
            max-height: 100%;
            transition-timing-function: ease-in-out;
        }
    }
}

#size-chart-modal {
    .modal-dialog {
        @include media-breakpoint-down(sm) {
            max-width: 90%;
            margin: 20px auto;
        }
    }

    .modal-body {
        max-height: 100%;
        margin-top: 45px;
    }
}

.sizechart-container {
    h3 {
        width: 100%;
        text-align: center;
        margin: 0 0 30px;
    }

    .size {
        .heading4 {
            background: #393b42;
            color: #fff;
            margin-bottom: 0;
            padding: 0 5px;
            line-height: 1.4;
        }

        ul {
            list-style: none;
            list-style-type: none;
            margin: 0;
            padding: 5px;

            li {
                line-height: 1.8;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .sizechart-container {
        width: 100%;
        max-width: 800px;
        padding: 0 10px 80px;

        h3 {
            text-align: center;
            margin: 30px 0;
        }

        .sizechart {
            width: 100%;
            display: flex;
            flex-flow: nowrap;
            text-align: left;
        }

        .size {
            width: 50%;
        }
    }
}

@include media-breakpoint-down(sm) {
    .sizechart-container {
        width: 100%;
        padding: 0 0 30px;

        .sizechart {
            display: flex;
            flex-direction: column;
        }

        .size {
            min-height: 120px;
            padding-bottom: 20px;
            width: 100%;
        }
    }
}

.pdp-slider {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;

    @include media-breakpoint-down(xs) {
        flex-direction: column-reverse;
    }

    ul.slider {
        padding: 0;
        list-style-type: none;
    }

    .icon-play {
        background-image: url("../../images/ui/icon_play_button_outline.svg");
    }

    .slider {
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: 84%;
        }

        .slick-dots {
            display: flex;
            justify-content: center;
            padding: 0;
            margin: 14px 0;

            li {
                display: inline-block;
                border: 1px solid $slate;
                width: 20px;
                height: 20px;
                background: $white;
                border-radius: 10px;
                margin: 0 5px;

                &.slick-active {
                    background: $black;
                }
            }

            button {
                display: none;
            }
        }

        .main-image {
            &.pdp-sheath-image {
                display: flex;
                justify-content: center;

                img {
                    margin-top: 5%;
                    max-height: 500px;
                    width: 100%;
                }
            }

            img {
                object-fit: contain;
                /* stylelint-disable-next-line property-no-unknown */
                aspect-ratio: 1/1;
            }

            &:focus-visible {
                outline: none;
                border: none;
            }
        }

        .pdp-video.product-video {
            position: relative;
            display: block;
            cursor: pointer;

            .video-image {
                margin-top: 15%;
                /* stylelint-disable-next-line property-no-unknown */
                aspect-ratio: auto;

                &.fallback {
                    margin-top: 0;
                    /* stylelint-disable-next-line property-no-unknown */
                    aspect-ratio: 1/1;
                }
            }

            .play-button {
                background-image: url("../../images/ui/icon_play_button_filled.svg");
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                position: absolute;
                height: 70px;
                width: 70px;
                top: 60%;
                left: 50%;
                transform: translate(-50%, -50%);

                @include media-breakpoint-up(sm) {
                    height: 90px;
                    width: 90px;
                }
            }

            .video-thumbnail {
                position: absolute;
                top: 0;
            }

            .video-thumbnail + .play-button {
                top: 50%;
            }
        }
    }

    ul.slider-nav-thumbnails {
        padding: 0;
        list-style-type: none;
        flex-shrink: 0;
        margin-left: -15px;
        margin-right: -15px;
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -ms-overflow-style: none;
        /* stylelint-disable-next-line property-no-unknown */
        scrollbar-width: none;
        height: 50px;

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        @include media-breakpoint-up(sm) {
            width: 60px;
            margin-left: unset;
            margin-right: 2%;
            display: block;
            height: unset;
        }

        @include media-breakpoint-up(md) {
            width: 80px;
            margin-right: 4%;
        }

        @include media-breakpoint-up(lg) {
            width: 80px;
        }

        li {
            clear: none;
            width: 100% !important;
            max-width: 45px;
            max-height: 45px;
            margin-right: 10px;
            margin-bottom: 0;
            border: 1px solid $slate-v1;
            cursor: pointer;
            flex-shrink: 0;

            &:first-child {
                margin-left: 15px;
            }

            @include media-breakpoint-up(sm) {
                clear: left;
                max-width: 60px;
                max-height: 60px;
                margin-bottom: 10px;

                &:first-child {
                    margin-left: unset;
                }
            }

            @include media-breakpoint-up(md) {
                max-width: 80px;
                max-height: 80px;
            }

            button.img-thumb-btn {
                border: none;
                background-color: transparent;
                padding: 0;

                &:focus {
                    outline: none;
                }
            }

            &.pdp-sheath-image {
                display: flex;
                justify-content: center;

                img {
                    max-height: 100px;
                    width: 100%;
                    object-fit: contain;
                }
            }

            img {
                width: 60px;
                object-fit: contain;
                /* stylelint-disable-next-line property-no-unknown */
                aspect-ratio: 1/1;

                @include media-breakpoint-up(sm) {
                    width: 60px;
                }

                @include media-breakpoint-up(md) {
                    width: 80px;
                }
            }

            &:hover,
            &:focus,
            &:focus-visible {
                outline: none;
            }

            &.current-thumb {
                border-color: $slate;
                border-width: 2.5px;
            }
        }

        .slick-prev.slick-arrow {
            background-color: $white;
            border: 1px solid $slate-v1;
            width: 100%;
            max-width: 60px;
            margin-bottom: 10px;
            color: transparent;
            position: relative;

            @include media-breakpoint-up(md) {
                max-width: 80px;
            }

            &:focus {
                outline: none;
            }

            &:focus-visible {
                border: 2px solid $slate;
            }

            &:hover {
                border-color: $slate;
            }

            &::before {
                content: url("../../images/ui/icon_arrow_up.svg");
                position: absolute;
                left: 38%;
                top: 9%;
            }
        }

        .slick-next.slick-arrow {
            background-color: $white;
            border: 1px solid $slate-v1;
            width: 100%;
            max-width: 60px;
            margin-top: 10px;
            color: transparent;
            position: relative;

            @include media-breakpoint-up(md) {
                max-width: 80px;
            }

            &:focus-visible {
                outline: none;
                border: 2px solid $slate;
            }

            &:hover {
                border-color: $slate;
            }

            &::before {
                content: url("../../images/ui/icon_arrow_down.svg");
                position: absolute;
                left: 31%;
                top: 9%;

                @include media-breakpoint-up(md) {
                    left: 35%;
                }

                @include media-breakpoint-up(lg) {
                    left: 38%;
                }
            }
        }

        .pdp-video {
            text-align: center;

            .icon {
                width: 30px;
                height: 40px;
                margin: 0 auto;
                vertical-align: middle;

                @include media-breakpoint-up(sm) {
                    width: 40px;
                    height: 60px;
                }

                @include media-breakpoint-up(md) {
                    width: 50px;
                    height: 80px;
                }
            }
        }
    }

    &.showVideo {
        .slick-dots {
            li:last-child {
                background-image: url("../../images/ui/icon_play_button.svg");
                background-repeat: no-repeat;
                background-size: contain;

                &.slick-active {
                    background-color: $black;
                    filter: brightness(5);
                    border: 1px solid $black;
                }
            }
        }
    }

    &.knifeless {
        .slider {
            @include media-breakpoint-down(md) {
                width: 100%;
                max-width: 760px;
            }

            .slick-dots {
                display: none;
            }
        }

        .slider-nav-thumbnails-knifeless {
            @include h4();

            writing-mode: vertical-lr;
            transform: rotate(180deg);
            text-orientation: mixed;
            text-transform: uppercase;

            a:hover {
                text-decoration: none;
            }

            .icon-arrow-down {
                height: 25px;
                width: 25px;
                margin-top: 15px;
                transform: rotate(270deg);
            }
        }
    }
}

.knifeless-show-more-button {
    @include h4();

    text-transform: uppercase;

    a:hover {
        text-decoration: none;
    }

    .icon-arrow-down {
        height: 25px;
        width: 25px;
        margin-left: 15px;
    }
}

.product-set-detail {
    &.container {
        @include media-breakpoint-down(lg) {
            padding: 0;
        }
    }

    .product-breadcrumb {
        margin-bottom: 60px;
        font-family: $brand-font;
        text-transform: uppercase;
        color: $black;

        .breadcrumb {
            .breadcrumb-item:last-child {
                a {
                    color: $gray-ada;
                }
            }

            @include media-breakpoint-down(lg) {
                border: none;
            }
        }

        @include media-breakpoint-down(md) {
            margin: 20px 0 0;
        }
    }

    @include media-breakpoint-down(lg) {
        .product-set-detail__description {
            padding-right: 20px;
        }
    }

    .pdp-slider .slider {
        @include media-breakpoint-up(sm) {
            width: 82%;
        }
    }

    @include media-breakpoint-down(md) {
        .product-set-detail__description {
            padding: 0 20px;
        }

        .pdp-slider {
            .slider {
                width: 100%;
                max-width: 100%;

                .slick-slide {
                    img {
                        max-width: 400px;
                        width: 100%;
                        margin: 0 auto;
                    }
                }
            }
        }
    }

    .primary-images {
        position: absolute;
        top: 0;
        left: 0;

        @include media-breakpoint-down(md) {
            position: relative;
        }
    }

    ul.slider-nav-thumbnails {
        width: auto;
        margin-left: 0;
        padding-left: 20px;

        @include media-breakpoint-up(lg) {
            display: block;
            width: 80px;
        }

        @include media-breakpoint-up(xl) {
            width: 100px;
        }
    }

    h1 {
        @include h2();

        @include media-breakpoint-down(md) {
            @include h3();

            margin-bottom: 5px;
        }

        margin: 0;
    }

    .prices {
        padding-top: 10px;
    }

    .set-item {
        margin: 0;
        margin-top: 17px;

        @include media-breakpoint-down(lg) {
            padding: 0 20px;
        }

        .product-number-rating {
            .text-m {
                text-transform: uppercase;
                font-family: $brand-font;
                font-size: 12px;
                margin-left: 10px;
            }
        }

        .prices {
            padding-top: 20px;
            padding-bottom: 0;
        }
    }

    .set-item__details-container {
        border: 1px solid $slate;
        border-width: 1px 0;
        margin-top: 15px;
    }

    .set-item__details-links {
        display: flex;
        flex-direction: row;

        a {
            text-decoration: none;
        }

        &:hover {
            .set-item__details-link.selected:not(:hover)::after {
                transform: translate(0, 4px);
            }
        }

        .set-item__details-link {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $brand-font;
            text-transform: uppercase;
            height: 30px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            padding: 0 12.5px;

            &::after {
                content: '';
                position: absolute;
                top: auto;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 4px;
                background: $blue;
                transform: translate(0, 4px);
                transition: all 0.5s cubic-bezier(0.4, 0.01, 0.1, 1);
            }

            &:hover,
            &.selected,
            &:focus-visible {
                &::after {
                    transform: scale(1.3);
                }
            }
        }
    }

    .set-item__details-content {
        border-bottom: 1px solid $slate;

        .product-detail__attributes-specs-list-content,
        .set-item__details-content-list {
            padding: 15px 0;
        }

        ul {
            padding-left: 0;
        }

        li {
            list-style-type: none;
        }

        .product-detail__attributes-specs-group {
            margin-bottom: 20px;

            &:last-child {
                margin: 0;
            }

            span {
                font-family: $univers-bold;
                text-transform: uppercase;
            }
        }
    }

    .product-name {
        margin-bottom: 10px;
        text-transform: uppercase;

        @include h4();

        a {
            text-decoration: none;
        }
    }

    .set-item__image {
        padding: 0;
        position: relative;

        @include media-breakpoint-down(lg) {
            padding: 0 20px;
            display: flex;
            justify-content: center;
        }

        .badge-tool-count {
            position: absolute;
        }

        img {
            width: 230px;
            @include media-breakpoint-down(lg) {
                width: 100%;
                max-width: 400px;
            }
        }
    }

    .product-set-price,
    .price {
        @include h5();

        text-align: left;
    }

    .cart-and-ipay {
        text-align: left;
        padding: 0;
    }

    .add-all-cart-and-ipay {
        text-align: left;
        margin-top: 8px;
        margin-bottom: 30px;

        &.loyalty-on {
            margin-bottom: 10px;
        }

        .add-to-cart {
            width: initial;
        }

        .add-all-cart-error-msg {
            margin-top: 8px;
            color: $red;
            max-width: 350px;
        }

        @include media-breakpoint-down(lg) {
            padding-left: 0;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 20px;

            .add-to-cart {
                width: 100%;
            }
        }
    }
}

.product-set-breadcrumbs {
    margin: 0;
}

#apparel-size-chart-modal {
    .close {
        z-index: 1;
    }

    .modal-dialog {
        max-width: 850px;
    }

    .modal-body {
        padding: 57px 90px 90px;
        @include media-breakpoint-down(md) {
            padding: 17px 20px 20px;
        }
    }

    .apparelsizechart__heading {
        @include h3();

        text-transform: uppercase;
    }

    .apparelsizechart__subheading {
        margin: 15px 0 20px;
        @include media-breakpoint-down(md) {
            margin: 10px 0 15px;
        }
    }

    .apparelsizechart__table-img {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
            display: block;
        }

        img {
            height: 337px;
            margin-left: 20px;
            @include media-breakpoint-down(md) {
                width: 100%;
                height: auto;
                margin: 0;
                max-width: 400px;
            }
        }

        figcaption {
            margin-left: 20px;
            @include media-breakpoint-down(md) {
                margin-left: 0;
            }
        }
    }

    .apparelsizechart__table {
        .table {
            width: 400px;
            margin: 0;
        }

        .table_EU {
            width: 530px;
            margin: 0;
        }

        thead th {
            background-color: $slate;
            color: $white;
        }

        th,
        td {
            width: 100px;
            height: 40px;
            border: 1px solid $slate;
            font-weight: normal;
        }

        @include media-breakpoint-down(md) {
            overflow: scroll;
            margin-bottom: 20px;
            max-width: 412px;
            border-left: 1px solid $slate;
            border-right: 1px solid $slate;

            thead th:first-child {
                left: 0;
                z-index: 1;
            }

            thead th {
                position: -webkit-sticky; /* for Safari */
                position: sticky;
                top: 0;
            }

            tbody th {
                position: -webkit-sticky; /* for Safari */
                position: sticky;
                left: 0;
                background-color: $white;
            }

            th,
            td {
                box-shadow: 1px 0 0 0 $slate;
                border: none;
                border-top: 1px solid $slate;
                border-bottom: 1px solid $slate;
            }
        }
    }

    .apparelsizechart_howtomeasure-heading {
        @include h4();

        text-transform: uppercase;
        margin: 15px 0;
    }

    .apparelsizechart_bold {
        font-family: $body-font-bold;
    }

    ul {
        list-style: none;
        padding: 0;
    }
}

.screen-reader-only {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

figure.containerZoom {
    background-size: 0;
    position: relative;
    width: 100%;
    overflow: hidden;
    cursor: zoom-in;
    margin: 0;
}

figure.containerZoom img {
    transition: opacity 0.5s;
    display: block;
    width: 100%;
}

figure.containerZoom.active img {
    opacity: 0;
}

