@import "../variables";
@import "../components/headers";

.price {
    color: $slate;

    .strike-through {
        text-decoration: line-through;
        color: $gray-ada;
        margin-right: 7px;
    }

    del {
        text-decoration: none;

        + .sales {
            color: $red;
        }
    }

    .tiered {
        color: $grey7;

        table {
            border-top: 1px solid $grey3;
            margin: 0 auto;

            tr {
                &:nth-child(odd) {
                    background-color: $grey2;
                }
            }
        }

        td,
        span.price {
            font-size: 0.875rem;
        }

        td {
            padding: 0.313rem;

            &.quantity {
                font-weight: normal;
                text-align: right;
            }

            &.value {
                text-align: left;
            }
        }

        .table-header {
            font-size: 1.125rem;
            padding: 0.313rem;
        }

        .column-header {
            font-size: 1rem;
            padding: 0.313rem;
            font-weight: normal;
        }

        .column-header.quantity {
            text-align: right;
        }
    }

    .flow-tax-included {
        font-size: $base-font-size;
        font-family: $univers;
        font-weight: $font-weight-heavy;
    }
}

.prices .price {
    max-width: 100%;
    display: inline-block;
}

.ships_free {
    @include h5();

    text-transform: uppercase;
    padding-left: 5px;
}
