.icon {
    display: inline-block;
    width: $icon-size;
    height: $icon-size;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

// Social

@mixin icon-social() {
    background-color: $yellow;
    border-radius: 50%;
    transition: 0.25s;
    position: relative;

    &::after {
        display: inline-block;
        content: '';
        width: 90%;
        height: 90%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &:hover {
        background-color: $blue;
    }
}

.icon-social {
    @include icon-social();
}

@mixin icon-facebook() {
    &::after {
        background-image: url("../images/social/facebook.svg");
    }
}

.icon-facebook {
    @include icon-facebook();
}

.icon-instagram {
    &::after {
        background-image: url("../images/social/instagram.svg");
    }
}

.icon-pinterest {
    &::after {
        background-image: url("../images/social/pinterest.svg");
    }
}

@mixin icon-twitter() {
    &::after {
        background-image: url("../images/social/xlogo-01.svg");
    }
}

.icon-twitter {
    @include icon-twitter();
}

.icon-youtube {
    &::after {
        background-image: url("../images/social/youtube.svg");
    }
}

.icon-tiktok {
    &::after {
        background-image: url("../images/social/tiktok.svg");
    }
}

// Custom icons
.icon-account {
    background-image: url("../images/ui/icon_account.svg");
}

@mixin icon-arrow-down() {
    background-image: url("../images/ui/icon_arrow_down.svg");
}

.icon-arrow-down {
    @include icon-arrow-down();
}

@mixin icon-arrow-down-light() {
    background-image: url("../images/ui/icon_arrow_down_light.svg");
}

.icon-arrow-down-light {
    @include icon-arrow-down-light();
}

@mixin icon-arrow-left() {
    background-image: url("../images/ui/icon_arrow_left.svg");
}

.icon-arrow-left {
    @include icon-arrow-left();
}

@mixin icon-arrow-right() {
    background-image: url("../images/ui/icon_arrow_right.svg");
}

.icon-arrow-right {
    @include icon-arrow-right();
}

@mixin icon-arrow-left-color($color) {
    background-color: $color;
    mask-image: url("../images/ui/icon_arrow_left.svg");
    mask-size: contain;
    mask-position: 50%;
    mask-repeat: no-repeat;
    background-image: none;
}

@mixin icon-arrow-right-color($color) {
    background-color: $color;
    mask-image: url("../images/ui/icon_arrow_right.svg");
    mask-size: contain;
    mask-position: 50%;
    mask-repeat: no-repeat;
    background-image: none;
}

@mixin icon-arrow-up() {
    background-image: url("../images/ui/icon_arrow_up.svg");
}

.icon-arrow-up {
    @include icon-arrow-up();
}

.icon-bookmark-filled {
    background-image: url("../images/ui/icon_bookmark_filled.svg");
}

.icon-bookmark-outline {
    background-image: url("../images/ui/icon_bookmark_outline.svg");
}

.icon-bookmark-hover {
    background-image: url("../images/ui/icon_bookmark_outline.svg");
    transition: 0.25s;

    &:hover {
        background-image: url("../images/ui/icon_bookmark_filled.svg");
    }
}

.icon-cart {
    background-image: url("../images/ui/icon_cart.svg");
    position: relative;

    .icon-cart__item-count {
        position: absolute;
        top: -4px;
        right: -8px;
        background: $yellow;
        width: 18px;
        height: 18px;
        border-radius: 8px;
        font-size: 12px;
        line-height: 19px;
        text-align: center;
        font-family: $body-font-bold;
    }
}

.icon-checkmark {
    background-image: url("../images/ui/icon_checkmark.svg");
}

.icon-close {
    background-image: url("../images/ui/icon_close.svg");
}

.icon-custom {
    background-image: url("../images/ui/icon_custom.svg");
}

.icon-engrave {
    background-image: url("../images/ui/icon_engrave.svg");
}

.icon-hamburger {
    background-image: url("../images/ui/icon_hamburger.svg");
}

.icon-heart-filled {
    background-image: url("../images/ui/icon_heart_filled.svg");
}

.icon-heart-outline {
    background-image: url("../images/ui/icon_heart_outline.svg");
}

.icon-heart-hover {
    background-image: url("../images/ui/icon_heart_outline.svg");
    transition: 0.25s;

    &:hover {
        background-image: url("../images/ui/icon_heart_filled.svg");
    }
}

.icon-info {
    background-image: url("../images/ui/icon_info.svg");
}

.icon-leatherman {
    background-image: url("../images/ui/icon_leatherman.svg");
}

.icon-lock {
    background-image: url("../images/ui/icon_lock.svg");
}

.icon-location {
    background-image: url("../images/ui/icon_location.svg");
}

.icon-minus {
    background-image: url("../images/ui/icon_minus.svg");
}

.icon-play {
    background-image: url("../images/ui/icon_play_button.svg");
}

.icon-plus {
    background-image: url("../images/ui/icon_plus.svg");
}

.icon-sale {
    background-image: url("../images/ui/icon_sale.svg");
}

.icon-search {
    background-image: url("../images/ui/icon_search.svg");
}

.icon-share {
    background-image: url("../images/ui/icon_share.svg");
}

.icon-star-filled {
    background-image: url("../images/ui/icon_star_filled.svg");
}

.icon-star-outline {
    background-image: url("../images/ui/icon_star_outline.svg");
}

.icon-star-hover {
    background-image: url("../images/ui/icon_star_outline.svg");
    transition: 0.25s;

    &:hover {
        background-image: url("../images/ui/icon_star_filled.svg");
    }
}

.icon-loyalty-star {
    background-image: url("../icons/loyalty/menu/star-slate.svg");
    border-radius: 50%;
}

.icon-store {
    background-image: url("../images/ui/icon_store.svg");
}

.icon-support {
    background-image: url("../images/ui/icon_support.svg");
}

.icon-free-ship {
    background-image: url("../images/ui/icon_free-ship.svg");
}

.icon-warranty-shield {
    background-image: url("../images/ui/icon_warranty-shield.svg");
}

.icon-american-flag {
    background-image: url("../images/ui/icon_american-flag.svg");
}
