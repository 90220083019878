@import "toastMessage";

@mixin field-label {
    text-transform: uppercase;
    font-family: $brand-font-condensed;
    font-weight: $base-font-weight;
    color: $slate;
}

.variation-label,
.fitsize.variation-label {
    text-transform: uppercase;
    font-family: $brand-font-condensed;
    font-weight: $base-font-weight;
    color: $slate;
    font-size: $base-font-size;
}

.attribute {
    margin-top: 0.938em;

    label {
        display: block;
        font-size: $base-font-size;
        @include field-label();
    }

    .non-input-label {
        display: block;
        margin-bottom: 0.5rem;
        font-size: $base-font-size;
        @include field-label();

        .non-input-value {
            display: inline;
            text-transform: none;
            font-family: $body-font;
            font-weight: $base-font-weight;
        }
    }

    select.error {
        border-color: $red;
        color: $red;
    }
}

.product-options {
    label {
        @include field-label();
    }

    .non-input-label {
        @include field-label();
    }
}

.swatch a {
    text-decoration: none;
}

.primary-images {

    @include media-breakpoint-down(xs) {
        margin: 0;
        padding: 0;
    }
}

.prices-add-to-cart-actions {
    width: 100%;

    .price {
        text-align: center;
    }
}

.prices {
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    text-align: left;
}

.prices-add-to-cart-actions:not(.stick-to-top) {
    .cart-and-ipay {
        text-align: center;
        padding: 0 10px;
        row-gap: 15px;

        .cart-and-ipay-action {
            padding: 0 5px;
        }

        .pdp-apple-pay-button {
            padding: 0 5px;
        }

        @include media-breakpoint-only(xs) {
            .btn {
                display: block;
            }
        }
    }
}

.prices-add-to-cart-actions.stick-to-top {
    .cart-and-ipay-action {
        padding-right: 0;
        padding-left: 0;
    }

    .pdp-apple-pay-button {
        display: none;
    }

    .notify-me-container {
        display: none;
    }
}

.add-to-cart-messages {
    @include toast-message();
}

.add-to-basket-alert {
    @include toast-alert();
}

.simple-quantity {
    margin-top: 1em;
}

.size-chart {
    margin-top: 1.071em;
}

div.availability {
    margin-top: 1.071em;
}

.bundle-item {
    padding-bottom: 1em;
    border-bottom: 1px solid $hr-border-color;

    &:last-child {
        border-bottom: none;
    }
}

.container.product-detail-wrapper.product-detail {
    margin-bottom: 150px;

    @include media-breakpoint-down(md) {
        margin-bottom: 40px;
    }
}

.container.product.product-detail {
    margin-top: 2em;

    @include media-breakpoint-down(md) {
        margin-top: 0;
    }
}

.product-option:not(:first-child) {
    margin-top: 1.071em;
}
