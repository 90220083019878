@import "variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "productCard";
@import "components/headers";
@import "components/spectrumGroupDetails";

.minicart {
    position: relative;

    @include text-body();

    h1 {
        font-size: 1rem;
    }

    .cart {
        padding: 0;
        background-color: $slate-v2;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }

    .minicart__items {
        padding: 20px;
        padding-top: 17px;
    }

    .minicart__clickable {
        &:focus {
            outline: none;
        }

        /* stylelint-disable selector-pseudo-class-no-unknown */
        &:focus-visible {
            .minicart__item-details-name {
                outline: 2px solid $slate;
                border-radius: $border-radius;
                outline-offset: 2px;
            }
        }
    }

    .minicart__title {
        @include h5();

        margin: 0;
        text-transform: uppercase;
    }

    .minicart__viewcart {
        @include h6();

        text-align: right;
        text-transform: uppercase;
        margin-top: 3px;

        a {
            color: $slate;
        }
    }

    .minicart__header {
        margin-bottom: 17px;
    }

    .minicart__item-details-edit-remove {
        display: flex;
        flex-flow: row;
        margin-top: 14px;
    }

    .minicart__item-details-edit,
    .minicart__item-details-remove {
        @include h6();

        text-transform: uppercase;
        text-decoration: underline;
        cursor: pointer;
        border: none;
        background-color: transparent;
        padding-left: 0;
        line-height: 1;

        &:focus {
            outline: none;
        }

        &:focus-visible {
            outline: 2px solid $slate;
            border-radius: $border-radius;
            outline-offset: 2px;
        }

        &.focused {
            border: 2px solid $slate;
            border-radius: $border-radius;
            padding: 2px;
        }
    }

    .minicart__item-details-edit {
        margin-right: 20px;

        .product-edit.btn-link {
            padding: 1px 0;

            a.edit {
                color: $black;
                letter-spacing: normal;
                line-height: 1;
                height: 18px;
            }
        }
    }

    .minicart__item-details {
        vertical-align: top;
        padding-left: 0;

        a {
            &:hover {
                text-decoration: none;
            }
        }
    }

    .minicart__item-details-name {
        @include h5();

        text-transform: uppercase;
    }

    .remove-btn {
        color: $slightly-darker-gray;
        float: right;
        background-color: white;
        border: none;
        font-size: 1.625em;
        margin-top: -0.313em;
        padding: 0;
    }

    .product-summary {
        margin-right: -0.938em;
        max-height: 260px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 0.938em;

        &.has-error {
            max-height: 200px;
        }

        ul {
            margin-left: 0;
            padding-left: 0;

            li {
                list-style-type: none;
            }
        }
    }

    .card {
        border: none;
    }

    .card-body {
        padding: 20px;
    }

    .item-image {
        width: 100px;
        height: 100px;

        img.product-image {
            width: 100%;
            height: auto;
        }
    }

    .quantity-label,
    .quantity-label h3 {
        font-family: $brand-font;
        font-size: $heading-6-font-size;
        text-transform: uppercase;
    }

    .quantity-wrapper {
        .span-icon-minus button,
        .span-icon-plus button {
            &.focused {
                border: 2px solid $slate;
                border-radius: $border-radius;
                outline-offset: 2px;
            }
        }
    }

    .quantity {
        width: 100%;
    }

    .popover {
        background: transparent;
        top: calc(100% - 1px);
        left: auto;
        right: -15px;
        width: 400px;
        max-width: 400px;
        height: 0;
        display: block;
        border: 0;
        transition: height 0.5s cubic-bezier(0.4, 0.01, 0.1, 1);
        overflow: hidden;

        &::before {
            left: auto;
            right: 15px;
        }

        &::after {
            left: auto;
            right: 16px;
        }

        &.show {
            height: auto;
            border-top: 0;
            border-right: 0;
        }

        @include media-breakpoint-down(md) {
            top: calc(100% - 1px);
        }

        @include media-breakpoint-down(xs) {
            width: 100vw;
            max-width: 100vw;
            right: -65px;
        }
    }

    .minicart-footer {
        border-top: 1px solid $grey3;
        padding: 20px;
        padding-top: 13px;

        .checkout-continue {
            margin-top: 6px;

            a.focused {
                box-shadow: inset 0 0 0 3px $slate;
            }
        }
    }

    .sub-total-label {
        font-size: 16px;
        font-weight: 600;
    }

    .sub-total {
        font-size: 16px;
    }

    .line-item-divider {
        margin: 0.625em -0.625em 0.625em -1.325em;
    }

    .line-item-name {
        width: 90%;
    }

    .minicart__unit-price {
        display: flex;
        margin-top: 20px;

        .col-4 {
            padding: 0;
            width: 80px;
            flex: initial;
        }
    }

    .minicart__item-price {
        width: 60px;
        margin-right: 30px;
    }

    .minicart__quantity {
        margin-right: 30px;
    }

    .minicart__subtotal {
        width: 60px;
    }
}

// what is going on here?
.hide-link-med {

    @include media-breakpoint-only(sm) {
        display: none;
    }
}

.hide-no-link {

    @include media-breakpoint-up(md) {
        display: none;
    }

    @include media-breakpoint-down(xs) {
        display: none;
    }
}

.minicart-popover-background {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1020;
    width: 100vw;
    height: 100vh;
    background-color: #000;

    &.fade {
        opacity: 0;
    }

    &.show {
        opacity: 0.7;
    }
}

.minicart-close-icon .icon {
    filter: invert(1);
    margin-left: 46%;
    margin-top: 22px;
}
