.newsletter-signup {
    .email-description {
        font-family: $brand-font;
        font-size: 20px;
        line-height: 1.2;
        letter-spacing: normal;
        text-transform: uppercase;
        margin-bottom: 10px;
    }

    .dpc {
        flex-wrap: nowrap;

        .input-group-append {
            width: calc(100% - 16px);
        }
    }

    .dpcCheckbox {
        height: 16px;
        width: 16px;
        border-radius: 2px;
        margin-right: 7px;
        background-color: $white;
        margin-top: 3px;
    }

    .privacy-copy {
        font-family: $body-font;
        font-size: 12px;
        line-height: 1.42;
        letter-spacing: normal;
        margin-top: 16px;

        a {
            font-family: $body-font-bold;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .dpc-copy {
        font-family: $body-font;
        font-size: 14px;
        line-height: 1.42;
        letter-spacing: normal;
        margin-bottom: 15px;

        p {
            margin-bottom: 0;

            a {
                text-decoration: none;
                color: white;
                font-family: $body-font-bold;
            }
        }
    }

    .visually-hidden {
        display: none;
    }

    .email-error,
    .dpc-error {
        display: none;
        color: $red;
        max-width: 500px;
    }

    .dpc-error {
        margin-bottom: 14px;
        max-width: none;
    }

    .dpc-input {
        margin-top: 15px;
    }

    .email-input {
        input::placeholder {
            color: $gray-ada;
        }

        @include media-breakpoint-down(lg) {
            max-width: 100%;
        }
    }

    .confirmation-message {
        @include media-breakpoint-down(xs) {
            padding-top: 63px;
            padding-bottom: 67px;
        }

        .confirmation-title {
            font-family: $brand-font;
            font-size: 25px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: uppercase;
            margin-bottom: 5px;
        }

        .confirmation-copy {
            font-family: $body-font;
            font-size: 14px;
            line-height: 1.42;
            letter-spacing: normal;
        }
    }
}

.newsletter-popup {
    padding: 0;
    @include media-breakpoint-down(md) {
        padding: 0;
    }
}

.popup-dpc-error {
    display: none;
    width: 100%;
    margin: 0;
    font-size: 80%;
    color: #d0021b;
    border: none;
    background-color: none;
}

.popup-email-error {
    display: none;
    width: 100%;
    margin: 0;
    font-size: 80%;
    color: #d0021b;
    border: none;
    background-color: none;
}

.newsletter-popup-submitted {
    display: block;
    text-align: center;
}

/* stylelint-disable */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    #newsletterPopupModal .popup-dialog {
        width: auto;

        .popup-content {
            width: 503px;
        }

        .email-input {
            width: 401px;
        }
    }
}
