@font-face {
    font-family: "TradeGothicW01-BoldCn20_675334";
    src: url("../fonts/brand/67fc974b-bcc7-48aa-bff0-15c824085f7d.woff2") format("woff2"), url("../fonts/brand/30bc8000-1637-4079-9dd6-d93c7c1d8245.woff") format("woff");
}

@font-face {
    font-family: "Univers LT W05_55 Roman";
    src: url("../fonts/brand/7b452431-e4a6-48a8-8ba1-c6300f9384df.woff2") format("woff2"), url("../fonts/brand/95c27e18-fc4d-4c73-aef2-171dc6f1ddb3.woff") format("woff");
}

@font-face {
    font-family: "Univers LT W04_65 Bold1475972";
    src: url("../fonts/brand/73d8af9e-4b82-4953-81a5-8941aed30297.woff2") format("woff2"), url("../fonts/brand/19ff06fe-d0c5-4294-a237-a4c9b85312b8.woff") format("woff");
}
